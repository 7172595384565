define("m12-2020/controllers/ergebnis", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_obj = {
    kompaktergebnis: "",
    pdfErstellt: false,
    pdfLink: "",
    pdfUnderConstruction: false,
    pdfFileName: "",
    applicationController: Ember.inject.controller('application'),
    // lasteinwirkungController: inject('lasteinwirkung'),
    downloadcenterController: Ember.inject.controller('downloadcenter'),
    // ergebnisController: inject('verbindungsmittel'),
    superController: Ember.inject.controller('supercontroller'),
    init: function () {
      this._super(); // var self = this;
      // if (self.get('applicationController').get('lasteinwirkungInvalid') || self.get('applicationController').get('bauteileInvalid')) {
      //   this.transitionToRoute('projektdaten');
      // } else {
      //   console.log("lasteinwirkung ist valid");
      // }

    },
    uebertrageBild: function (bildIndex, filename, imgData) {
      var self = this;
      var server = self.get('applicationController').get('server');
      var pfad = self.get('applicationController').get('pfad');
      console.log('sende image: ' + filename);
      var url = "";
      var environment = externalConfig.environments[self.get('applicationController').get('environment')];
      url = externalConfig[environment].uriImageservice;

      _jquery.default.ajax({
        type: "POST",
        url: url,
        data: {
          name: filename,
          b64: imgData
        }
      }).done(function (data) {
        if (bildIndex === 1) {
          self.set('triggerPDFabrufen', !self.get('triggerPDFabrufen'));
          self.pdfAbrufen();
        }
      });
    },
    pdfAbrufen: function () {
      console.log('PDF Abruf');
      var self = this;
      var applicationdata = self.get('applicationController').get('model').application.objectAt(0);
      var cid = applicationdata.get('Calculation_ID');
      var selectedScrew = applicationdata.get('selectedScrew');
      var projektdatendata = self.get('applicationController').get('model').projektdaten.objectAt(0);
      var projektdatendataJSON = JSON.stringify(projektdatendata);
      var server = self.get('applicationController').get('server');
      var pfad = self.get('applicationController').get('pfad');
      console.log('PDF cid: ' + cid + 'ende');

      _jquery.default.ajax({
        type: "POST",
        url: server + "erzeugeAusgabe/",
        data: {
          cid: cid,
          projektdaten: projektdatendataJSON,
          artnr: selectedScrew
        }
      }).done(function (data, statusText, xhr) {
        self.get('superController').logaufruf("erzeugeAusgabe", xhr.status);
        self.set('pdfErstellt', true);
        applicationdata.set('pdfErstellt', true);
        self.set('pdfUnderConstruction', false);
        self.set('pdfLink', data);
        self.set('pdfFileName', cid + ".pdf");
        self.get('downloadcenterController').set('pdfFile', data);
        self.get('downloadcenterController').set('pdfErstellt', true);
        console.log(data);
      });
    },
    setPNGimageData: function () {
      var self = this;
      var i = 0;
      var applicationdata = self.get('applicationController').get('model').application.objectAt(0);
      var x3d = self.get('applicationController').get('model').x3ddefault.objectAt(0);
      var cid = applicationdata.get('Calculation_ID');
      x3d.set('istGitterModell', false);
      document.getElementById('navInfo').setAttribute('transitiontime', '0');
      self.get('applicationController').zentriertObjekt();
      var viewpointarray = Array('viewpointDefault', 'viewpointLinks', 'viewpointOben', 'viewpointFront');
      var imageNameArray = Array('ImgNameIsoView_', 'ImgNameLeftView_', 'ImgNameTopView_', 'ImgNameFrontView_');
      var pngImages = [{
        id: 0,
        typ: "viewpointDefault",
        name: "",
        imgData: "",
        msg: ""
      }, {
        id: 1,
        typ: "viewpointLinks",
        name: "",
        imgData: "",
        msg: ""
      }, {
        id: 2,
        typ: "viewpointOben",
        name: "",
        imgData: "",
        msg: ""
      }, {
        id: 3,
        typ: "viewpointFront",
        name: "",
        imgData: "",
        msg: ""
      }];
      i = 0;
      var myInterval = setInterval(function () {
        if (i === 0) {
          document.getElementById('viewpointDefault').setAttribute('set_bind', 'true'); // x3d.set('abstaendeAnzeigen', false);

          x3d.get('bauteile').findBy('id', x3d.get('lastViewpoint')).get('cameraProperties').objectAt(0).set('setBind', false);
          x3d.set('lastViewpoint', 'viewpointDefault');
          x3d.get('bauteile').findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).set('setBind', true);
        }

        if (i > 0) {
          document.getElementById('viewpointDefault').setAttribute('set_bind', 'true');
          var imgUrl = document.getElementById("x3d").runtime.getScreenshot();
          var imgData = imgUrl.toString().replace('data:image/png;base64,', '');
          var filename = imageNameArray[i - 1] + cid + '.png'; // x3d.set('abstaendeAnzeigen', true);

          self.uebertrageBild(i, filename, imgData);
        }

        if (i === 1) {
          document.getElementById('viewpointDefault').setAttribute('set_bind', true);
          self.get('applicationController').zentriertObjekt();
          x3d.get('bauteile').findBy('id', x3d.get('lastViewpoint')).get('cameraProperties').objectAt(0).set('setBind', false);
          x3d.set('lastViewpoint', 'viewpointDefault');
          x3d.get('bauteile').findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).set('setBind', true);
          x3d.set('viewpointHelper', !x3d.get('viewpointHelper'));
          document.getElementById('navInfo').setAttribute('transitiontime', '1');
          clearInterval(myInterval);
        }

        i++;

        if (i < 2) {
          document.getElementById(viewpointarray[i - 1]).setAttribute('set_bind', 'true');
          x3d.get('bauteile').findBy('id', x3d.get('lastViewpoint')).get('cameraProperties').objectAt(0).set('setBind', false);
          x3d.set('lastViewpoint', viewpointarray[i - 1]);
          x3d.get('bauteile').findBy('id', viewpointarray[i - 1]).get('cameraProperties').objectAt(0).set('setBind', true);
          x3d.set('viewpointHelper', !x3d.get('viewpointHelper'));
        }
      }, 500);
      return pngImages;
    },

    createPDF(event) {
      var self = this;
      self.setPNGimageData();

      if (self.get('pdfErstellt') === false) {
        self.set('pdfUnderConstruction', true);
      }
    },

    showPrintView(event) {
      var self = this;
      var printview = window.open("", "Druckansicht", "width=600, height=800");
      printview.document.write("<font face='Arial'><table><tr><td width='200' height='80'><center><img src='assets/images/wuerth.jpg' width='150'></center></td><td width='100%' bgcolor='#ff0000'><font color='#ffffff'>Würth bemessungssoftware</font></td></tr></table>");
      printview.document.write("<hr></hr>");
      printview.document.write("<h2>M06</h2>");
      printview.document.write(self.get('kompaktergebnis'));
      printview.document.write("<hr></hr>");
      printview.document.write("<img src='assets/images/wuerth_group.png' width='75'> <small>(c) SWG Engineering 2016</small></font>");
    },

    setKompaktergebnis(event) {
      var self = this; // var div = document.createElement('div');
      //
      // div.className = 'row';
      //
      // div.innerHTML = self.kompaktergebnis;
      // document.getElementById('kompaktergebnis').appendChild(div);

      (0, _jquery.default)("#kompaktergebnis").html(self.get('kompaktergebnis'));
    },

    resetErstellt(identifier, value, filePath) {
      var self = this;
      var link = document.createElement('a');
      link.href = filePath;
      link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
      link.click();
      self.set(identifier + 'Erstellt', value);
      self.get('applicationController').get('model').application.objectAt(0).set(identifier + 'Erstellt', value);
      self.get('downloadcenterController').set(identifier + 'Erstellt', value);
    }

  }, (_applyDecoratedDescriptor(_obj, "createPDF", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "createPDF"), _obj), _applyDecoratedDescriptor(_obj, "showPrintView", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "showPrintView"), _obj), _applyDecoratedDescriptor(_obj, "setKompaktergebnis", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "setKompaktergebnis"), _obj), _applyDecoratedDescriptor(_obj, "resetErstellt", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "resetErstellt"), _obj)), _obj));

  _exports.default = _default;
});