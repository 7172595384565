define("m12-2020/templates/components/arctwo-d", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yRH8CG86",
    "block": "{\"symbols\":[],\"statements\":[[7,\"shape\",true],[8],[0,\"\\n  \"],[7,\"appearance\",true],[8],[0,\"\\n    \"],[7,\"material\",true],[11,\"emissivecolor\",[23,0,[\"emissivecolor\"]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"arc2D\",true],[10,\"id\",\"bogenHorzinotalerWinkel\"],[11,\"radius\",[23,0,[\"radius\"]]],[11,\"startAngle\",[23,0,[\"anfangsWinkel\"]]],[11,\"endAngle\",[23,0,[\"endWinkel\"]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"tex-t\",[],[[\"@name\",\"@model\"],[[23,0,[\"name\"]],[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m12-2020/templates/components/arctwo-d.hbs"
    }
  });

  _exports.default = _default;
});