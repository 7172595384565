define("m12-2020/models/projektdaten", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // var lower = 0;
  // var upper = 100000000;
  // var calcId = Math.floor(Math.random()*(upper-lower))+lower;
  var application = _model.default.extend({
    bname: (0, _model.attr)('string'),
    bvname: (0, _model.attr)('string'),
    bvplz: (0, _model.attr)('string'),
    bvstrasse: (0, _model.attr)('string', {
      defaultValue: function () {
        return 'false';
      }
    }),
    bvort: (0, _model.attr)('string'),
    vname: (0, _model.attr)('string'),
    vstrasse: (0, _model.attr)('string'),
    vplz: (0, _model.attr)('string'),
    vort: (0, _model.attr)('string'),
    vtel: (0, _model.attr)('string'),
    vfax: (0, _model.attr)('string'),
    vemail: (0, _model.attr)('string'),
    bezeichnung: (0, _model.attr)('string'),
    beschreibung: (0, _model.attr)('string'),
    kdnum: (0, _model.attr)('string'),
    bemerkungstext: (0, _model.attr)('string')
  });

  var _default = application;
  _exports.default = _default;
});