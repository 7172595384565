define("m12-2020/models/screw-property", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    headformID: (0, _model.attr)('number'),
    headdiameter: (0, _model.attr)('number'),
    headheight: (0, _model.attr)('number'),
    length: (0, _model.attr)('number'),
    diameter: (0, _model.attr)('number'),
    threadlengthtip: (0, _model.attr)('number'),
    threadlengthhead: (0, _model.attr)('number')
  });

  _exports.default = _default;
});