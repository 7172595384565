define("m12-2020/templates/components/indexedfaceset-erg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JnGiOODx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"coordinate\",true],[11,\"point\",[23,0,[\"koordinaten\"]]],[8],[9],[0,\"\\n\"],[7,\"color\",true],[11,\"color\",[23,0,[\"farbe\"]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m12-2020/templates/components/indexedfaceset-erg.hbs"
    }
  });

  _exports.default = _default;
});