define("m12-2020/components/cylinde-r", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Cylinder = Ember.Component.extend({
    tagName: 'cylinder'
  });
  var _default = Cylinder;
  _exports.default = _default;
});