define("m12-2020/models/verbindungsmittel", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Verbindungsmittel = _model.default.extend({
    title: (0, _model.attr)("String"),
    children: (0, _model.hasMany)('tag', {
      async: true
    }),
    parent: (0, _model.belongsTo)('tag', {
      async: true
    })
  });

  var _default = Verbindungsmittel;
  _exports.default = _default;
});