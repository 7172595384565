define("m12-2020/templates/components/multiselect-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "njmINDWB",
    "block": "{\"symbols\":[\"value\"],\"statements\":[[4,\"each\",[[23,0,[\"objekte\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[\"visible\"]]],null,{\"statements\":[[0,\"    \"],[5,\"selectbox-item\",[],[[\"@value\",\"@filter\"],[[23,1,[]],[23,0,[\"filtern\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m12-2020/templates/components/multiselect-box.hbs"
    }
  });

  _exports.default = _default;
});