define("m12-2020/templates/components/shap-e", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "klsbB4Ag",
    "block": "{\"symbols\":[],\"statements\":[[5,\"appearanc-e\",[],[[\"@name\",\"@model\"],[[23,0,[\"name\"]],[23,0,[\"model\"]]]],{\"statements\":[[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"istExtrusion\"]]],null,{\"statements\":[[0,\"  \"],[5,\"extrusio-n\",[],[[\"@name\",\"@model\"],[[23,0,[\"name\"]],[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"istSchraube\"]]],null,{\"statements\":[[0,\"  \"],[5,\"schraubeextrusio-n\",[],[[\"@name\",\"@model\"],[[23,0,[\"name\"]],[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m12-2020/templates/components/shap-e.hbs"
    }
  });

  _exports.default = _default;
});