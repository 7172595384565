define("m12-2020/components/shape-erg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Shape = Ember.Component.extend({
    tagName: 'Shape',
    layoutName: 'shape-erg',
    attributeBindings: ['bboxcenter', 'bboxsize', 'ispickable', 'DEF', 'USE'],
    bboxcenter: "0,0,0",
    bboxsize: "-1,-1,-1",
    ispickable: "0",
    DEF: Ember.computed('', function () {
      var name = this.get('name');
      var value = '';

      if (name === 'schraube') {
        value = 'schraube';
      }

      return value;
    }),
    // TODO: besser DEF USE für die Schrauben verwenden. Bislang noch keine Lösung gefunden
    // USE: function () {
    //   var name = this.get('name');
    //
    //   var value = '';
    //
    //   if (name === 'schraube2'){
    //     value = 'schraube';
    //   }
    //   return value;
    //
    // }.property(''),
    istExtrusion: Ember.computed('', function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var istextrusion = false;
      var item = bauteile.findBy('id', name);

      if (item.get('typ') === "verbindungsmittel") {
        istextrusion = true;
      }

      if (name.substring(0, 8) === 'schraube' || name === 'nebenTraeger' || name.substring(0, 17) === 'verstaerkungBlass') {
        istextrusion = true;
      }

      return istextrusion;
    }),
    istFaceset: Ember.computed('', function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var istFaceset = false;
      var item = bauteile.findBy('id', name);

      if (item.get('typ') === "platte" || name.substring(0, 12) === 'hauptTraeger' || name.substring(0, 18) === 'verstaerkungWerner') {
        istFaceset = true;
      }

      return istFaceset;
    })
  });
  var _default = Shape;
  _exports.default = _default;
});