define("m12-2020/components/viewpoin-t", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Viewpoint = Ember.Component.extend({
    tagName: 'OrthoViewpoint',
    attributeBindings: ['orientation', 'position', 'fieldofview', 'centerofrotation', 'description', 'set_bind', 'jump', 'retainUserOffsets'],
    centerofrotation: "0 0 0",
    description: 'camera',
    jump: true,
    retainUserOffsets: false,
    fieldofview: Ember.computed('foobar', function () {
      var values = '-5, -5, 5, 5';
      return values;
    }),
    orientation: Ember.computed('foobar', function () {
      var name = this.get('name');
      var m06 = this.get('model');
      var values;
      var item = m06.objectAt(0).get('bauteile').findBy('id', name);
      values = item.get('orientations').objectAt(0).get('x') + " " + item.get('orientations').objectAt(0).get('y') + " " + item.get('orientations').objectAt(0).get('z') + " " + item.get('orientations').objectAt(0).get('winkel');
      return values;
    }),
    position: Ember.computed('foobar', function () {
      var name = this.get('name');
      var m06 = this.get('model');
      var values;
      var item = m06.objectAt(0).get('bauteile').findBy('id', name);
      var x = item.get('positions').objectAt(0).get('x');
      var y = item.get('positions').objectAt(0).get('y');
      var z = item.get('positions').objectAt(0).get('z');
      values = x + " " + y + " " + z;
      return values;
    }),
    //set_bind wird in bower_components/viewpointmanagement/vpmanagement.js gesetzt
    set_bind: Ember.computed('foobar', function () {
      var name = this.get('name');
      var m06 = this.get('model');
      var value = '';
      var item = m06.objectAt(0).get('bauteile').findBy('id', name);
      value = item.get('cameraProperties').objectAt(0).get('setBind');
      return value;
    })
  });
  var _default = Viewpoint;
  _exports.default = _default;
});