define("m12-2020/templates/components/appearanc-e", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yuZpODvN",
    "block": "{\"symbols\":[],\"statements\":[[5,\"materia-l\",[],[[\"@name\",\"@model\"],[[23,0,[\"name\"]],[23,0,[\"model\"]]]],{\"statements\":[[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m12-2020/templates/components/appearanc-e.hbs"
    }
  });

  _exports.default = _default;
});