define("m12-2020/components/schraub-e", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Schraube = Ember.Component.extend({
    tagName: 'transform',
    layoutName: 'schraub-e',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['schraubenKlasse'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    center: Ember.computed('foobar', function () {
      var values = '0 0 0';
      return values;
    }),
    translation: Ember.computed('model.firstObject.transformHelper', function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var x,
          y,
          z = 0;

      if (x3d.get('ergebnisGeladen') === true) {
        x = bauteile.findBy('id', name).get('punkt').objectAt(0).get('x') / 10;
        y = bauteile.findBy('id', name).get('punkt').objectAt(0).get('y') / 10;
        z = bauteile.findBy('id', name).get('punkt').objectAt(0).get('z') / 10;
      } else {
        x = bauteile.findBy('id', name).get('translations').objectAt(0).get('x') / 10;
        y = bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', name).get('screwProperties').objectAt(0).get('length') / 10;
        z = bauteile.findBy('id', name).get('translations').objectAt(0).get('z') / 10;
      }

      var values = x + ' ' + y + ' ' + z;
      return values;
    }),
    // Rotation in der XY-Ebene, um die z-Achse
    // Rotation in der XY-Ebene, um die z-Achse
    rotation: Ember.computed('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt', function () {
      var self = this;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile'); // var drehwinckelXAchse = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').findBy('id', name).get('x');
      // var drehwinckelYAchse = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').findBy('id', name).get('y');

      var values = '';
      var drehwinckelYAchse = 0,
          drehwinckelZAchse = 0;
      var x = 0,
          y = 0,
          z = 0;

      if (x3d.get('ergebnisGeladen') === true) {
        x = bauteile.findBy('id', name).get('richtungsvektor').objectAt(0).get('x');
        y = bauteile.findBy('id', name).get('richtungsvektor').objectAt(0).get('y');
        z = bauteile.findBy('id', name).get('richtungsvektor').objectAt(0).get('z');

        if (Math.abs(y) !== 1) {
          drehwinckelYAchse = self.getWinkel({
            x: x,
            y: 0,
            z: z
          }, {
            x: 0,
            y: 0,
            z: 1
          });
        } else {
          drehwinckelYAchse = self.getWinkel({
            x: x,
            y: y,
            z: z
          }, {
            x: 0,
            y: 0,
            z: 1
          });
        }

        drehwinckelZAchse = self.getWinkel({
          x: x,
          y: y,
          z: z
        }, {
          x: 0,
          y: 1,
          z: 0
        });
      } else {
        drehwinckelZAchse = Number(this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').objectAt(0).get('z'));
      }

      var vorzeichen = 1;

      if (x !== 0) {
        vorzeichen = x / Math.abs(x);
      }

      var xWertRotationsachse = Math.cos(drehwinckelYAchse);
      var zWertRotationsachse = vorzeichen * -Math.sin(drehwinckelYAchse); // console.log('Bauteil: '+name);
      // console.log('x: '+x+' y: '+y+' z: '+z);
      // console.log('xWertRotationsachse: '+xWertRotationsachse);
      // console.log('zWertRotationsachse: '+zWertRotationsachse);
      // console.log('drehwinckelZAchse: '+drehwinckelZAchse);

      values = xWertRotationsachse + ' 0 ' + zWertRotationsachse + ' ' + drehwinckelZAchse;
      return values;
    }),
    getWinkel: function (vektor, bezugsVektor) {
      var self = this;
      var xx = vektor.x * bezugsVektor.x;
      var yy = vektor.y * bezugsVektor.y;
      var zz = vektor.z * bezugsVektor.z;
      var l_vektor = self.vektorLaenge(vektor);
      var l_bezugsVektor = self.vektorLaenge(bezugsVektor);
      var winkel = Math.acos((xx + yy + zz) / (l_vektor * l_bezugsVektor));
      return winkel;
    },
    vektorLaenge: function (v) {
      return Math.sqrt(Math.pow(v.x, 2) + Math.pow(v.y, 2) + Math.pow(v.z, 2));
    }
  });
  var _default = Schraube;
  _exports.default = _default;
});