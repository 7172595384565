define("m12-2020/components/x-tree-children", ["exports", "ember-simple-tree/components/x-tree-children"], function (_exports, _xTreeChildren) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _xTreeChildren.default;
    }
  });
});