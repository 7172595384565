define("m12-2020/templates/components/shape-erg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E9X2PHfr",
    "block": "{\"symbols\":[],\"statements\":[[5,\"appearanc-e\",[],[[\"@name\",\"@model\"],[[23,0,[\"name\"]],[23,0,[\"model\"]]]],{\"statements\":[[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[0,\"\\n\"],[4,\"if\",[[23,0,[\"istFaceset\"]]],null,{\"statements\":[[0,\"  \"],[5,\"indexedfaceset-erg\",[],[[\"@name\",\"@model\"],[[23,0,[\"name\"]],[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m12-2020/templates/components/shape-erg.hbs"
    }
  });

  _exports.default = _default;
});