define("m12-2020/models/boxsize", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    x: (0, _model.attr)('number'),
    y: (0, _model.attr)('number'),
    z: (0, _model.attr)('number')
  });

  _exports.default = _default;
});