define("m12-2020/components/schraubeextrusio-n", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Extrusion = Ember.Component.extend({
    tagName: 'extrusion',
    attributeBindings: ['endCap', 'solid', 'creaseAngle', 'crosssection', 'orientation', 'scale', 'spine'],
    endCap: true,
    solid: true,
    ispickable: "0",
    creaseAngle: 7,
    schraubenLaenge: function () {
      // Die Schraubenänge sollte ein vielfaches von 6 sein
      // da eine Schraubenumdrehung aus 6 Stufen besteht
      var name = this.get('name');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var x = 0;
      x = bauteile.findBy('id', name).get('screwProperties').objectAt(0).get('length');
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
      var headID = Number(item.get('screwProperties').objectAt(0).get('headformID'));

      if ((headID === 1 || headID === 2 || headID === 3 || headID === 7 || headID === 10 || headID === 11) && Number(bauteile.findBy('id', name).get('screwProperties').objectAt(0).get('headheight')) > 0.2) {} else if (Number(bauteile.findBy('id', name).get('screwProperties').objectAt(0).get('headheight')) < 0.2) {
        x = x + 0.05;
      } else {
        x = x - Number(item.get('screwProperties').objectAt(0).get('headheight'));
      }

      return Math.abs(x);
    },
    //crossection beschreibt die zu extrodierende Grundform in der xz-Ebene
    crosssection: Ember.computed('model.firstObject.transformHelper', function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = false;
      var x;
      var y;
      var z;
      var item = bauteile.findBy('id', name);

      if (name.substring(0, 8) === 'schraube' || item.get('typ') === "verbindungsmittel") {
        var schraubenDurchmesser = bauteile.findBy('id', name).get('screwProperties').objectAt(0).get('diameter') * 0.5;
        var kopfformID = parseInt(bauteile.findBy('id', name).get('screwProperties').objectAt(0).get('headformID'));
        x = schraubenDurchmesser / 2;

        if (kopfformID === 3) {
          var x2 = schraubenDurchmesser * Math.cos(1.0472) / 2;
          z = schraubenDurchmesser * Math.sin(1.0472) / 2;
          values = [-x, 0, -x2, z, x2, z, x, 0, x2, -z, -x2, -z, -x, 0].toString();
        } else {
          var x2 = schraubenDurchmesser * Math.cos(0.5236) / 2;
          var x3 = schraubenDurchmesser * Math.cos(1.0472) / 2;
          var z2 = schraubenDurchmesser * Math.sin(0.5236) / 2;
          var z3 = schraubenDurchmesser * Math.sin(1.0472) / 2;
          z = x * 0.5 - 0.05;
          values = [-x, 0, -x2, z2, -x3, z3, 0, x, x3, z3, x2, z2, x, 0, x2, -z2, x3, -z3, 0, -x, -x3, -z3, -x2, -z2, -x, 0].toString();
        }
      }

      return values;
    }),
    //orientation beschreibt die Richtung in der die Extrusion erfolgt
    //Wird die Extrusion in mehreren Schritten/Stufen durchgeführt, ist die Richtung für jede Stufe anzugeben.
    orientation: Ember.computed('model.firstObject.transformHelper', function () {
      var name = this.get('name');
      var x3ddefault = this.get('model');
      var bauteile = x3ddefault.objectAt(0).get('bauteile');
      var values = '0 1 0 0, 0 1 0 0';
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      if ((name.substring(0, 8) === 'schraube' || item.get('typ') === "verbindungsmittel") && Number(item.get('screwProperties').objectAt(0).get('headheight')) > 0.2) {
        var schraubenlaenge = Number(this.schraubenLaenge());
        var delta = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('diameter') / 40;
        var stufen = Math.floor(schraubenlaenge / (10 * delta));

        if (stufen % 2 !== 0) {
          stufen = stufen + 1;
        }

        var sum = 0;

        for (var i = 0; i < stufen / 6; i++) {
          for (var j = 1; j < 7; j++) {
            sum = sum + 1;

            if ((sum + 2) * delta > Number(bauteile.findBy('id', name).get('screwProperties').objectAt(0).get('threadlengthtip')) / 10) {
              if (sum * delta > schraubenlaenge / 10 - Number(bauteile.findBy('id', name).get('screwProperties').objectAt(0).get('threadlengthhead')) / 10) {
                //Rotationswerte für das Gewinde unter dem Kopf
                values = values + ', 0 1 0 ' + j * 1.047;
              } else {
                //Rotationswerte für den Schafft - im Schaftbereich keine Rotation, daher 0
                values = values + ', 0 1 0 0';
              }
            } else {
              //Rotationswerte für das Gewinde an der Spitze
              values = values + ', 0 1 0 ' + j * 1.047;
            }

            if (sum === stufen) {
              break;
            }

            ;
          }
        }

        values = values + ', 0 1 0 0';
      }

      return values;
    }),
    //scale beschreibt die Skalierung der Grundfläche während eines Extrusionschritts
    //Wird die Extrusion in mehreren Schritten/Stufen durchgeführt, ist die Skalierung für jeden Schritt/Stufe anzugeben.
    scale: Ember.computed('model.firstObject.transformHelper', function () {
      var name = this.get('name');
      var x3ddefault = this.get('model');
      var bauteile = x3ddefault.objectAt(0).get('bauteile');
      var values = '0 0, 0.025 0.025, 0.05 0.05, 0.07 0.07';
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      if (name.substring(0, 8) === 'schraube' && Number(item.get('screwProperties').objectAt(0).get('headheight')) > 0.2 || item.get('typ') === "verbindungsmittel" && Number(item.get('screwProperties').objectAt(0).get('headheight')) > 0.2) {
        var schraubenlaenge = Number(this.schraubenLaenge());
        var delta = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('diameter') / 40;
        var stufen = Math.floor(schraubenlaenge / (10 * delta));

        if (stufen % 2 !== 0) {
          stufen = stufen + 1;
        }

        for (var i = 0; i < stufen; i++) {
          if ((i + 4) * delta > Number(bauteile.findBy('id', name).get('screwProperties').objectAt(0).get('threadlengthtip')) / 10) {
            if ((i + 4) * delta > schraubenlaenge / 10 - Number(bauteile.findBy('id', name).get('screwProperties').objectAt(0).get('threadlengthhead')) / 10 && (i + 4) * delta < schraubenlaenge / 10) {
              //Skalierungswerte für das Gewinde unter dem Kopf
              if (i % 2 !== 0) {
                values = values + ', 0.0975 0.0975';
              } else {
                values = values + ', 0.2 0.2';
              }
            } else {
              //Skalierungswerte für den Schaft
              values = values + ', 0.125 0.125';
            }
          } else {
            //Skalierungswerte für das Gewinde an der Spitze
            if ((i + 4) * delta < schraubenlaenge / 10) {
              if (i % 2 !== 0) {
                values = values + ', 0.0975 0.0975';
              } else {
                values = values + ', 0.2 0.2';
              }
            } else {
              //Skalierungswerte für den Kopfanfang
              values = values + ', 0.125 0.125';
            }
          }
        } //Mittels des kopfskalierungsfakrors wird der Durchmesser der Schraue an den Durchmesser des Kopfes angepasst


        var headdiameter = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('headdiameter') / 4;
        var screwdiameter = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('diameter');
        var kopfformID = parseInt(this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('headformID'));
        var kopffomSkalWerte = ''; //Entsprechend der Kopfformid kann der Schraubenkopf über max 7 Stufen extrodiert werden

        switch (kopfformID) {
          //Linsensenkkopf
          case 0:
            kopffomSkalWerte = headdiameter + ' ' + headdiameter + ', ' + 0.75 * headdiameter + ' ' + 0.75 * headdiameter;
            break;
          //PanHead

          case 1:
            kopffomSkalWerte = headdiameter + ' ' + headdiameter + ', ' + headdiameter + ' ' + headdiameter + ', ' + headdiameter + ' ' + headdiameter + ', ' + Number(0.75 * headdiameter) + ' ' + Number(0.75 * headdiameter);
            break;
          //Scheibenkopf

          case 2:
            kopffomSkalWerte = headdiameter + ' ' + headdiameter + ', ' + headdiameter + ' ' + headdiameter + ', ' + headdiameter + ' ' + headdiameter + ', ' + Number(0.6 * headdiameter) + ' ' + Number(0.6 * headdiameter) + ', ' + Number(0.5 * headdiameter) + ' ' + Number(0.5 * headdiameter) + ', ' + Number(0.4 * headdiameter) + ' ' + Number(0.4 * headdiameter);
            break;
          //Sechskantkopf

          case 3:
            kopffomSkalWerte = Number(headdiameter) + ' ' + Number(headdiameter) + ', ' + headdiameter + ' ' + headdiameter + ', ' + headdiameter + ' ' + headdiameter;
            break;
          //Senkfräskopf

          case 4:
            kopffomSkalWerte = headdiameter + ' ' + headdiameter;
            break;
          //Senkkopf

          case 5:
            kopffomSkalWerte = headdiameter + ' ' + headdiameter;
            break;
          //Zylinderkopf

          case 6:
            kopffomSkalWerte = headdiameter + ' ' + headdiameter + ', ' + headdiameter + ' ' + headdiameter + ', ' + 0.9 * headdiameter + ' ' + 0.9 * headdiameter;
            break;
          //Tellerkopf

          case 7:
            kopffomSkalWerte = headdiameter + ' ' + headdiameter + ', ' + headdiameter + ' ' + headdiameter + ', ' + headdiameter + ' ' + headdiameter + ', ' + Number(0.6 * headdiameter) + ' ' + Number(0.6 * headdiameter) + ', ' + Number(0.5 * headdiameter) + ' ' + Number(0.5 * headdiameter) + ', ' + Number(0.4 * headdiameter) + ' ' + Number(0.4 * headdiameter);
            break;
          //Senkkopf HSP

          case 8:
            kopffomSkalWerte = headdiameter + ' ' + headdiameter;
            break;
          //Birnenkopf

          case 9:
            kopffomSkalWerte = headdiameter + ' ' + headdiameter + ', ' + headdiameter + ' ' + headdiameter + ', ' + 0.85 * headdiameter + ' ' + 0.85 * headdiameter + ', ' + 0.75 * headdiameter + ' ' + 0.75 * headdiameter;
            break;
          //Außentorx

          case 10:
            kopffomSkalWerte = headdiameter + ' ' + headdiameter + ', ' + headdiameter + ' ' + headdiameter + ', ' + Number(0.7 * headdiameter) + ' ' + Number(0.7 * headdiameter) + ', ' + Number(0.675 * headdiameter) + ' ' + Number(0.675 * headdiameter);
            break;
          //Scheibenkopf Form 2

          case 11:
            kopffomSkalWerte = headdiameter + ' ' + headdiameter + ', ' + headdiameter + ' ' + headdiameter + ', ' + headdiameter + ' ' + headdiameter + ', ' + Number(0.6 * headdiameter) + ' ' + Number(0.6 * headdiameter) + ', ' + Number(0.5 * headdiameter) + ' ' + Number(0.5 * headdiameter) + ', ' + Number(0.4 * headdiameter) + ' ' + Number(0.4 * headdiameter);
            break;
          //ohne Kopf

          case 99:
            break;
          //KlammerRueckenNormal

          case 98:
            break;
          //KlammerRueckenBreit

          case 97:
            break;
        }

        values = values + ', ' + kopffomSkalWerte;
      } else if (name.substring(0, 8) === 'schraube' && Number(item.get('screwProperties').objectAt(0).get('headheight')) < 0.2 || item.get('typ') === "verbindungsmittel" && Number(item.get('screwProperties').objectAt(0).get('headheight')) < 0.2) {
        var schraubenlaenge = Number(this.schraubenLaenge());
        var delta = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('diameter') / 2;
        var stufen = Math.floor(schraubenlaenge / delta - 4);

        for (var i = 0; i < stufen; i++) {
          if ((i + 4) * (delta / 10) > Number(this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('threadlengthtip')) / 10) {
            if ((i + 4) * (delta / 10) > (schraubenlaenge - Number(this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('threadlengthhead'))) / 10) {
              //Skalierungswerte für das Gewinde unter dem Kopf
              values = values + ', 0.175 0.175, 0.2 0.2';
            } else {
              //Skalierungswerte für den Schaft
              values = values + ', 0.195 0.195, 0.195 0.195';
            }
          } else {
            //Skalierungswerte für das Gewinde an der Spitze
            values = values + ', 0.175 0.175, 0.2 0.2';
          }
        } //Mittels des kopfskalierungsfaktors wird der Durchmesser der Schraue an den Durchmesser des Kopfes angepasst


        var headdiameter = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('headdiameter');
        var screwdiameter = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('diameter');
        var kopfskalierungsfaktor = 0.2 * headdiameter / screwdiameter;
        var kopfformID = parseInt(this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('headformID'));
        var kopffomSkalWerte = '';
        kopffomSkalWerte = 0.2 + ' ' + 0.2 + ', ' + 0.2 + ' ' + 0.2 + ', ' + 0.2 + ' ' + 0.2 + ', ' + 0.2 + ' ' + 0.2 + ', ' + Number(kopfskalierungsfaktor) + ' ' + Number(kopfskalierungsfaktor) + ', ' + Number(kopfskalierungsfaktor) + ' ' + Number(kopfskalierungsfaktor) + ', ' + Number(kopfskalierungsfaktor) + ' ' + Number(kopfskalierungsfaktor);
        values = values + ', ' + kopffomSkalWerte;
      }

      return values;
    }),
    //spine beschreibt die Länge der Extrusion in y-Richtung
    //Die Extrusion kann in mehreren Schritten/Stufen erfolgen
    spine: Ember.computed('model.firstObject.transformHelper', function () {
      var name = this.get('name');
      var x3ddefault = this.get('model');
      var bauteile = x3ddefault.objectAt(0).get('bauteile');
      var values = '0 0 0';
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      if (name.substring(0, 8) === 'schraube' || item.get('typ') === "verbindungsmittel") {
        var x = 0;
        var y = 0;
        var z = 0;
        var schraubenLaenge = Number(this.schraubenLaenge()); //Länge der Stufen der Extursion
        //Länge der Schraube = Länge der Stufen * Stufen

        var delta = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('diameter') / 40; //Sufen der Extursion

        var stufen = Math.floor(schraubenLaenge / (10 * delta));

        if (stufen % 2 !== 0) {
          stufen = stufen + 1;
        }

        schraubenLaenge = schraubenLaenge / 10;

        for (var i = 0; i < stufen; i++) {
          y = y + delta;
          values = values + ', ' + x + ' ' + y + ' ' + z;
        }

        var headheight = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('headheight') / 10;

        if (y > schraubenLaenge) {
          y = y - (y - schraubenLaenge);
          values = values + ', ' + x + ' ' + y + ' ' + z;
        } else if (y < schraubenLaenge) {
          y = y + (schraubenLaenge - y);
          values = values + ', ' + x + ' ' + y + ' ' + z;
        } else {
          values = values + ', ' + x + ' ' + y + ' ' + z;
        }

        values = values + ', ' + x + ' ' + y + ' ' + z + ', ' + x + ' ' + y + ' ' + z;
        var kopfformID = parseInt(this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('screwProperties').objectAt(0).get('headformID')); //Entsprechend der Kopfformid wird über die Höhe des Kopfes die Form extrudiert

        switch (kopfformID) {
          //Linsensenkkopf
          case 0:
            values = values + ', ' + x + ' ' + (y + headheight) + ' ' + z + ', ' + x + ' ' + (y + 1.05 * headheight) + ' ' + z + ', ' + x + ' ' + (y + 1.075 * headheight) + ' ' + z + ', ' + x + ' ' + (y + 1.085 * headheight) + ' ' + z;
            break;
          //PanHead

          case 1:
            values = values + ', ' + x + ' ' + y + ' ' + z + ', ' + x + ' ' + y + ' ' + z + ', ' + x + ' ' + (y + 0.8 * headheight) + ' ' + z + ', ' + x + ' ' + (y + headheight) + ' ' + z;
            break;
          //Scheibenkopf

          case 2:
            values = values + ', ' + x + ' ' + y + ' ' + z + ', ' + x + ' ' + y + ' ' + z + ', ' + x + ' ' + (y + 0.4 * headheight) + ' ' + z + ', ' + x + ' ' + (y + 0.7 * headheight) + ' ' + z + ', ' + x + ' ' + (y + headheight) + ' ' + z;
            break;
          //Sechskantkopf

          case 3:
            values = values + ', ' + x + ' ' + y + ' ' + z + ', ' + x + ' ' + y + ' ' + z + ', ' + x + ' ' + (y + headheight) + ' ' + z;
            break;
          //Senkfräskopf

          case 4:
            values = values + ', ' + x + ' ' + (y + headheight) + ' ' + z;
            break;
          //Senkkopf

          case 5:
            values = values + ', ' + x + ' ' + (y + headheight) + ' ' + z;
            break;
          //Zylinderkopf

          case 6:
            values = values + ', ' + x + ' ' + y + ' ' + z + ', ' + x + ' ' + (y + 0.9 * headheight) + ' ' + z + ', ' + x + ' ' + (y + headheight) + ' ' + z;
            break;
          //Tellerkopf

          case 7:
            values = values + ', ' + x + ' ' + (y + 0.4 * headheight) + ' ' + z + ', ' + x + ' ' + (y + 0.7 * headheight) + ' ' + z + ', ' + x + ' ' + (y + headheight) + ' ' + z;
            break;
          //Senkkopf HSP

          case 8:
            values = values + ', ' + x + ' ' + (y + headheight) + ' ' + z;
            break;
          //Birnenkopf

          case 9:
            values = values + ', ' + x + ' ' + (y + headheight) + ' ' + z + ', ' + x + ' ' + (y + 1.05 * headheight) + ' ' + z + ', ' + x + ' ' + (y + 1.075 * headheight) + ' ' + z + ', ' + x + ' ' + (y + 1.085 * headheight) + ' ' + z;
            break;
          //Außentorx

          case 10:
            values = values + ', ' + x + ' ' + y + ' ' + z + ', ' + x + ' ' + (y + 0.35 * headheight) + ' ' + z + ', ' + x + ' ' + (y + 0.35 * headheight) + ' ' + z + ', ' + x + ' ' + (y + headheight) + ' ' + z;
            break;
          //Scheibenkopf Form 2

          case 11:
            values = values + ', ' + x + ' ' + y + ' ' + z + ', ' + x + ' ' + y + ' ' + z + ', ' + x + ' ' + (y + 0.4 * headheight) + ' ' + z + ', ' + x + ' ' + (y + 0.7 * headheight) + ' ' + z + ', ' + x + ' ' + (y + headheight) + ' ' + z;
            break;
        }
      }

      return values;
    })
  });
  var _default = Extrusion;
  _exports.default = _default;
});