define("m12-2020/components/shap-e", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Shape = Ember.Component.extend({
    tagName: 'shape',
    layoutName: 'shap-e',
    attributeBindings: ['bboxcenter', 'bboxsize', 'ispickable', 'DEF', 'USE'],
    bboxcenter: "0,0,0",
    bboxsize: "-1,-1,-1",
    ispickable: "0",
    DEF: Ember.computed('foobar', function () {
      var name = this.get('name');
      var value = '';

      if (name === 'schraube') {
        value = 'schraube';
      }

      return value;
    }),
    istExtrusion: Ember.computed('model.firstObject.transformHelper', function () {
      var name = this.get('name'); // console.log("shape name: ");
      // console.log(name);

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);
      var istextrusion = false;

      if (item.get('typ') === "box") {
        istextrusion = true;
      }

      return istextrusion;
    }),
    istSchraube: Ember.computed('foobar', function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var item = bauteile.findBy('id', name);
      var istSchraube = false;

      if (name.substring(0, 8) === 'schraube' || item.get('typ') === 'verbindungsmittel') {
        istSchraube = true;
      }

      return istSchraube;
    })
  });
  var _default = Shape;
  _exports.default = _default;
});