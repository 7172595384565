define("m12-2020/models/optionen", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var optionen = _model.default.extend({
    min_n0: (0, _model.attr)('number'),
    min_n90: (0, _model.attr)('number'),
    min_a1cPlatte: (0, _model.attr)('number'),
    min_a2cPlatte: (0, _model.attr)('number'),
    sicherheitsAbstand: (0, _model.attr)('number')
  });

  var _default = optionen;
  _exports.default = _default;
});