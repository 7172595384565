define("m12-2020/templates/components/language-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jnn0h//c",
    "block": "{\"symbols\":[\"loc\"],\"statements\":[[4,\"each\",[[24,[\"locales\"]]],null,{\"statements\":[[0,\"  \"],[7,\"option\",true],[11,\"value\",[29,[[23,1,[\"id\"]]]]],[8],[1,[23,1,[\"text\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m12-2020/templates/components/language-select.hbs"
    }
  });

  _exports.default = _default;
});