define("m12-2020/components/gabel-lager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = Ember.Component.extend({
    tagName: 'transform',
    layoutName: 'gabel-lager',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    objektSkalierung: 1,
    translation: Ember.computed('model.firstObject.transformHelper', function () {
      return "0 " + 0 + " 0";
    }),
    rotation: Ember.computed('model.firstObject.transformHelper', function () {
      return '0 0 0 0';
    }),
    koordinaten: Ember.computed('model.firstObject.transformHelper', function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = null;
      var item = bauteile.findBy('id', name);
      var x = item.get('boxsizes').objectAt(0).get('x') / 10;
      var y = item.get('boxsizes').objectAt(0).get('y') / 10;
      var z = item.get('boxsizes').objectAt(0).get('z') / 10;
      var spaltmass = Number(item.get('erweiterteeigenschaften').objectAt(0).get('abstandsmass')) / 10;
      var traegerbreite = bauteile.findBy('id', "traeger").get('boxsizes').objectAt(0).get('z') / 10;
      var traegerhoehe = bauteile.findBy('id', "traeger").get('boxsizes').objectAt(0).get('y') / 10;
      var zinkenbreite = z;
      var stuetzbreite = traegerbreite + 2 * zinkenbreite + 2 * spaltmass;
      var stutzhoehe = 0.5 * traegerhoehe;
      var h2 = 0;
      var h3 = x3d.get('hGabellagerEingetragen') === false ? traegerhoehe * 0.9 : y;
      var p0 = -(x / 2) + ' ' + -stutzhoehe + ' ' + -(stuetzbreite / 2 - zinkenbreite);
      var p1 = -(x / 2) + ' ' + -stutzhoehe + ' ' + (stuetzbreite / 2 - zinkenbreite);
      var p2 = x / 2 + ' ' + -stutzhoehe + ' ' + (stuetzbreite / 2 - zinkenbreite);
      var p3 = x / 2 + ' ' + -stutzhoehe + ' ' + -(stuetzbreite / 2 - zinkenbreite);
      var p4 = -(x / 2) + ' ' + -stutzhoehe + ' ' + -(stuetzbreite / 2 - zinkenbreite);
      var p5 = -(x / 2) + ' ' + -stutzhoehe + ' ' + (stuetzbreite / 2 - zinkenbreite);
      var p6 = x / 2 + ' ' + -stutzhoehe + ' ' + (stuetzbreite / 2 - zinkenbreite);
      var p7 = x / 2 + ' ' + -stutzhoehe + ' ' + -(stuetzbreite / 2 - zinkenbreite);
      var p8 = -(x / 2) + ' ' + -stutzhoehe + ' ' + -(stuetzbreite / 2);
      var p9 = -(x / 2) + ' ' + -stutzhoehe + ' ' + stuetzbreite / 2;
      var p10 = x / 2 + ' ' + -stutzhoehe + ' ' + stuetzbreite / 2;
      var p11 = x / 2 + ' ' + -stutzhoehe + ' ' + -(stuetzbreite / 2);
      var p12 = -(x / 2) + ' ' + h2 + ' ' + -(traegerbreite / 2 + spaltmass);
      var p13 = -(x / 2) + ' ' + h2 + ' ' + (traegerbreite / 2 + spaltmass);
      var p14 = x / 2 + ' ' + h2 + ' ' + (traegerbreite / 2 + spaltmass);
      var p15 = x / 2 + ' ' + h2 + ' ' + -(traegerbreite / 2 + spaltmass);
      var p16 = -(x / 2) + ' ' + h3 + ' ' + -(stuetzbreite / 2);
      var p17 = -(x / 2) + ' ' + h3 + ' ' + stuetzbreite / 2;
      var p18 = x / 2 + ' ' + h3 + ' ' + stuetzbreite / 2;
      var p19 = x / 2 + ' ' + h3 + ' ' + -(stuetzbreite / 2);
      var p20 = -(x / 2) + ' ' + h3 + ' ' + -(traegerbreite / 2 + spaltmass);
      var p21 = -(x / 2) + ' ' + h3 + ' ' + (traegerbreite / 2 + spaltmass);
      var p22 = x / 2 + ' ' + h3 + ' ' + (traegerbreite / 2 + spaltmass);
      var p23 = x / 2 + ' ' + h3 + ' ' + -(traegerbreite / 2 + spaltmass);
      values = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9 + ', ' + p10 + ', ' + p11 + ', ' + p12 + ', ' + p13 + ', ' + p14 + ', ' + p15 + ', ' + p16 + ', ' + p17 + ', ' + p18 + ', ' + p19 + ', ' + p20 + ', ' + p21 + ', ' + p22 + ', ' + p23; // console.log(values);

      return values;
    }),
    koordinatenIndex: Ember.computed('model.firstObject.transformHelper', function () {
      var values = "0 3 2 1 -1 1 2 6 5 -1 5 6 10 9 -1 9 10 18 17 -1 21 17 18 22 -1 13 21 22 14 -1 12 13 14 15 -1 12 15 23 20 -1 16 20 23 19 -1 8 16 19 11 -1 4 8 11 7 -1 0 4 7 3 -1 0 1 13 12 -1 4 20 16 8 -1 5 9 17 21 -1 2 3 15 14 -1 7 11 19 23 -1 6 22 18 10 -1";
      return values;
    }),
    konturKoordinatenIndex: Ember.computed('model.firstObject.transformHelper', function () {
      var values = "0 1 5 9 17 21 13 12 20 16 8 4 0 -1 3 2 6 10 18 22 14 15 23 19 11 7 3 -1 9 10 -1 17 18 -1 21 22 -1 13 14 -1 12 15 -1 20 23 -1 16 19 -1 8 11 -1";
      return values;
    }),
    farbIndex: Ember.computed('model.firstObject.transformHelper', function () {
      var values = "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0";
      return values;
    }),
    farbe: Ember.computed('model.firstObject.transformHelper', function () {
      var name = this.get('name');
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
      var values = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');
      return values;
    }),
    transparency: Ember.computed('model.firstObject.istGitterModell', function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
      var value = item.get('appearances').objectAt(0).get('transparency');

      if (x3d.get('istGitterModell') === true) {
        value = 1;
      }

      return value;
    }),
    hoeheMasskette: 0.5,
    abstandVomBauteil: 0.15,
    abstandVonDerMasslinie: 0.05,
    x: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'gabellager').get('boxsizes').objectAt(0).get('x')) / 10;
    }),
    y: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'gabellager').get('boxsizes').objectAt(0).get('y')) / 10;
    }),
    z: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'gabellager').get('boxsizes').objectAt(0).get('z')) / 10;
    }),
    spaltmass: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'gabellager').get('erweiterteeigenschaften').objectAt(0).get('abstandsmass')) / 10;
    }),
    traegerbreite: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 10;
    }),
    traegerhoehe: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y')) / 10;
    }),
    traegerlaenge: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('x')) / 10;
    }),
    auflagerhoehe: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y')) / 10;
    }),
    auflagerlaenge: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'auflager').get('boxsizes').objectAt(0).get('x')) / 10;
    }),
    winkelRadinat: function (grad) {
      return grad * Math.PI / 180;
    },
    gabelhoeheEingetragen: Ember.computed('model.firstObject.hGabellagerEingetragen', function () {
      return this.get('model').objectAt(0).get('hGabellagerEingetragen');
    }),
    gabelhoeheMasslinien: Ember.computed('model.firstObject.transformHelper', function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var drehwinkelX;
      var drehwinkelY;
      var drehwinkelZ;
      var x1 = this.get('x') / 2;
      var y1 = 0;
      var z1 = this.get('traegerbreite') / 2 + this.get('spaltmass') + this.get('z');
      var ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
      x1 = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : x1;
      var y2 = x3d.get('hGabellagerEingetragen') === false ? this.get('traegerhoehe') * 0.9 : this.get('y');

      if (x3d.get('bauteile').findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true) {
        if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
          x1 = this.get('traegerlaenge') / 2;
        }

        z1 = z1 + this.get('abstandVomBauteil');
        drehwinkelX = this.winkelRadinat(90);
        drehwinkelY = 0;
        drehwinkelZ = -this.winkelRadinat(90);
      } else {
        if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
          x1 = this.get('traegerlaenge') / 2;
        }

        x1 = x1 + this.get('abstandVomBauteil');
        drehwinkelX = 0;
        drehwinkelY = 0;
        drehwinkelZ = -this.winkelRadinat(90);
      }

      var x2 = x1;
      var z2 = z1;
      return this.getMassketteKoordinaten({
        x: x1,
        y: y1,
        z: z1
      }, {
        x: x2,
        y: y2,
        z: z2
      }, {}, {
        x: drehwinkelX,
        y: drehwinkelY,
        z: drehwinkelZ
      });
    }),
    gabelhoeheTextTranslation: Ember.computed('model.firstObject.transformHelper', function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var x = this.get('x') / 2;
      var y = this.get('y') / 2;
      var z = this.get('z') + this.get('spaltmass') + this.get('traegerbreite') / 2;
      var ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
      x = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : x;

      if (x3d.get('bauteile').findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true) {
        if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
          x = this.get('traegerlaenge') / 2;
        }

        z = z + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
      } else {
        if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
          x = this.get('traegerlaenge') / 2;
        }

        x = x + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
      }

      return x + ' ' + y + ' ' + z;
    }),
    gabelhoeheTextRotation: Ember.computed('model.firstObject.transformHelper', function () {
      var values;
      var x3d = this.get('model').objectAt(0);

      if (x3d.get('bauteile').findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true) {
        values = '-1 1 1 ' + this.winkelRadinat(240);
      } else {
        values = '0 0 1 ' + -this.winkelRadinat(90);
      }

      return values;
    }),
    gabelhoeheText: Ember.computed('model.firstObject.transformHelper', function () {
      return (this.get('y') * 10).toFixed(1);
    }),
    gabelhoeheColor: Ember.computed('model.firstObject.hGabellagerAktiv', function () {
      var x3d = this.get('model').objectAt(0);

      if (x3d.get('hGabellagerAktiv') === true) {
        return '1 0 0';
      } else {
        return '0 0 0';
      }
    }),
    gabellaengeEingetragen: Ember.computed('model.firstObject.lGabellagerEingetragen', function () {
      return this.get('model').objectAt(0).get('lGabellagerEingetragen');
    }),
    gabellaengeMasslinien: Ember.computed('model.firstObject.transformHelper', function () {
      var x3d = this.get('model').objectAt(0);
      var drehwinkelX;
      var drehwinkelY;
      var drehwinkelZ;
      var x1 = this.get('x') / 2;
      var y1 = -0.5 * this.get('traegerhoehe');
      var z1 = this.get('traegerbreite') / 2 + this.get('spaltmass') + this.get('z');
      var x2 = -x1;

      if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        z1 = z1 + this.get('hoeheMasskette') + 2 * this.get('abstandVomBauteil');
        drehwinkelX = this.winkelRadinat(90);
        drehwinkelY = 0;
        drehwinkelZ = 0;
      } else {
        y1 = y1 - this.get('hoeheMasskette') - 2 * this.get('abstandVomBauteil');
        drehwinkelX = this.winkelRadinat(180);
        drehwinkelY = 0;
        drehwinkelZ = 0;
      }

      var y2 = y1;
      var z2 = z1;
      return this.getMassketteKoordinaten({
        x: x1,
        y: y1,
        z: z1
      }, {
        x: x2,
        y: y2,
        z: z2
      }, {}, {
        x: drehwinkelX,
        y: drehwinkelY,
        z: drehwinkelZ
      });
    }),
    gabellaengeTextTranslation: Ember.computed('model.firstObject.transformHelper', function () {
      var x3d = this.get('model').objectAt(0);
      var x = 0;
      var y = -0.5 * this.get('traegerhoehe');
      var z = this.get('z') + this.get('spaltmass') + this.get('traegerbreite') / 2;

      if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        z = z + 2 * this.get('hoeheMasskette') + 2 * this.get('abstandVomBauteil') - this.get('abstandVonDerMasslinie');
      } else {
        y = y - 2 * this.get('hoeheMasskette') - 2 * this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
      }

      return x + ' ' + y + ' ' + z;
    }),
    gabellaengeTextRotation: Ember.computed('model.firstObject.transformHelper', function () {
      var values;
      var x3d = this.get('model').objectAt(0);

      if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        values = '1 0 0 ' + -this.winkelRadinat(90);
      } else {
        values = '0 0 0 0';
      }

      return values;
    }),
    gabellaengeText: Ember.computed('model.firstObject.transformHelper', function () {
      return (this.get('x') * 10).toFixed(1);
    }),
    gabellaengeColor: Ember.computed('model.firstObject.lGabellagerAktiv', function () {
      var x3d = this.get('model').objectAt(0);

      if (x3d.get('lGabellagerAktiv') === true) {
        return '1 0 0';
      } else {
        return '0 0 0';
      }
    }),
    gabelbreiteEingetragen: Ember.computed('model.firstObject.bGabellagerEingetragen', function () {
      return this.get('model').objectAt(0).get('bGabellagerEingetragen');
    }),
    gabelbreiteMasslinien1: Ember.computed('model.firstObject.transformHelper', function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var drehwinkelX;
      var drehwinkelY;
      var drehwinkelZ;
      var ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
      var x1 = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : this.get('x') / 2;
      var y1 = this.get('traegerhoehe') + 0.1 > this.get('y') ? this.get('traegerhoehe') + 0.1 : this.get('y');
      var z1 = -this.get('spaltmass') - this.get('traegerbreite') / 2;
      var z2 = -this.get('z') - this.get('spaltmass') - this.get('traegerbreite') / 2;

      if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
          x1 = this.get('traegerlaenge') / 2;
        }

        x1 = x1 + this.get('abstandVomBauteil');
        drehwinkelX = 0;
        drehwinkelY = -this.winkelRadinat(90);
        drehwinkelZ = -this.winkelRadinat(90);
      } else {
        y1 = y1 + this.get('abstandVomBauteil');
        drehwinkelX = 0;
        drehwinkelY = -this.winkelRadinat(90);
        drehwinkelZ = 0;
      }

      var x2 = x1;
      var y2 = y1;
      return this.getMassketteKoordinaten({
        x: x1,
        y: y1,
        z: z1
      }, {
        x: x2,
        y: y2,
        z: z2
      }, {}, {
        x: drehwinkelX,
        y: drehwinkelY,
        z: drehwinkelZ
      });
    }),
    gabelbreiteMasslinien2: Ember.computed('model.firstObject.transformHelper', function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var drehwinkelX;
      var drehwinkelY;
      var drehwinkelZ;
      var ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
      var x1 = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : this.get('x') / 2;
      var y1 = this.get('traegerhoehe') + 0.1 > this.get('y') ? this.get('traegerhoehe') + 0.1 : this.get('y');
      var z1 = this.get('z') + this.get('spaltmass') + this.get('traegerbreite') / 2;
      var z2 = this.get('spaltmass') + this.get('traegerbreite') / 2;

      if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
          x1 = this.get('traegerlaenge') / 2;
        }

        x1 = x1 + this.get('abstandVomBauteil');
        drehwinkelX = 0;
        drehwinkelY = -this.winkelRadinat(90);
        drehwinkelZ = -this.winkelRadinat(90);
      } else {
        y1 = y1 + this.get('abstandVomBauteil');
        drehwinkelX = 0;
        drehwinkelY = -this.winkelRadinat(90);
        drehwinkelZ = 0;
      }

      var x2 = x1;
      var y2 = y1;
      return this.getMassketteKoordinaten({
        x: x1,
        y: y1,
        z: z1
      }, {
        x: x2,
        y: y2,
        z: z2
      }, {}, {
        x: drehwinkelX,
        y: drehwinkelY,
        z: drehwinkelZ
      });
    }),
    gabelbreiteTextTranslation1: Ember.computed('model.firstObject.transformHelper', function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
      var x = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : this.get('x') / 2;
      var y = this.get('traegerhoehe') + 0.1 > this.get('y') ? this.get('traegerhoehe') + 0.1 : this.get('y');
      var z = -this.get('z') / 2 - this.get('spaltmass') - this.get('traegerbreite') / 2;

      if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
          x = this.get('traegerlaenge') / 2;
        }

        x = x + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
      } else {
        y = y + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
      }

      return x + ' ' + y + ' ' + z;
    }),
    gabelbreiteTextTranslation2: Ember.computed('model.firstObject.transformHelper', function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
      var x = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : this.get('x') / 2;
      var y = this.get('traegerhoehe') + 0.1 > this.get('y') ? this.get('traegerhoehe') + 0.1 : this.get('y');
      var z = +this.get('z') / 2 + this.get('spaltmass') + this.get('traegerbreite') / 2;

      if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
          x = this.get('traegerlaenge') / 2;
        }

        x = x + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
      } else {
        y = y + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
      }

      return x + ' ' + y + ' ' + z;
    }),
    gabelbreiteTextRotation: Ember.computed('model.firstObject.transformHelper', function () {
      var values;
      var x3d = this.get('model').objectAt(0);

      if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        values = '1 1 1 ' + this.winkelRadinat(240);
      } else {
        values = '0 1 0 ' + -this.winkelRadinat(90);
      }

      return values;
    }),
    gabelbreiteText: Ember.computed('model.firstObject.transformHelper', function () {
      return (this.get('z') * 10).toFixed(1);
    }),
    gabelbreiteColor: Ember.computed('model.firstObject.bGabellagerAktiv', function () {
      var x3d = this.get('model').objectAt(0);

      if (x3d.get('bGabellagerAktiv') === true) {
        return '1 0 0';
      } else {
        return '0 0 0';
      }
    }),
    spaltmassEingetragen: Ember.computed('model.firstObject.aGabellagerEingetragen', function () {
      return this.get('model').objectAt(0).get('aGabellagerEingetragen');
    }),
    gabelspaltmassMasslinien1: Ember.computed('model.firstObject.transformHelper', function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var drehwinkelX;
      var drehwinkelY;
      var drehwinkelZ;
      var ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
      var x1 = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : this.get('x') / 2;
      var y1 = this.get('traegerhoehe') + 0.1 > this.get('y') ? this.get('traegerhoehe') + 0.1 : this.get('y');
      var z1 = -this.get('traegerbreite') / 2;
      var z2 = -this.get('spaltmass') - this.get('traegerbreite') / 2;

      if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
          x1 = this.get('traegerlaenge') / 2;
        }

        x1 = x1 + this.get('abstandVomBauteil');
        drehwinkelX = 0;
        drehwinkelY = -this.winkelRadinat(90);
        drehwinkelZ = -this.winkelRadinat(90);
      } else {
        y1 = y1 + this.get('abstandVomBauteil');
        drehwinkelX = 0;
        drehwinkelY = -this.winkelRadinat(90);
        drehwinkelZ = 0;
      }

      var x2 = x1;
      var y2 = y1;
      return this.getMassketteKoordinaten({
        x: x1,
        y: y1,
        z: z1
      }, {
        x: x2,
        y: y2,
        z: z2
      }, {}, {
        x: drehwinkelX,
        y: drehwinkelY,
        z: drehwinkelZ
      });
    }),
    gabelspaltmassMasslinien2: Ember.computed('model.firstObject.transformHelper', function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var drehwinkelX;
      var drehwinkelY;
      var drehwinkelZ;
      var ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
      var x1 = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : this.get('x') / 2;
      var y1 = this.get('traegerhoehe') + 0.1 > this.get('y') ? this.get('traegerhoehe') + 0.1 : this.get('y');
      var z1 = this.get('spaltmass') + this.get('traegerbreite') / 2;
      var z2 = this.get('traegerbreite') / 2;

      if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
          x1 = this.get('traegerlaenge') / 2;
        }

        x1 = x1 + this.get('abstandVomBauteil');
        drehwinkelX = 0;
        drehwinkelY = -this.winkelRadinat(90);
        drehwinkelZ = -this.winkelRadinat(90);
      } else {
        y1 = y1 + this.get('abstandVomBauteil');
        drehwinkelX = 0;
        drehwinkelY = -this.winkelRadinat(90);
        drehwinkelZ = 0;
      }

      var x2 = x1;
      var y2 = y1;
      return this.getMassketteKoordinaten({
        x: x1,
        y: y1,
        z: z1
      }, {
        x: x2,
        y: y2,
        z: z2
      }, {}, {
        x: drehwinkelX,
        y: drehwinkelY,
        z: drehwinkelZ
      });
    }),
    gabelspaltmassTextTranslation1: Ember.computed('model.firstObject.transformHelper', function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
      var x = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : this.get('x') / 2;
      var y = this.get('traegerhoehe') + 0.1 > this.get('y') ? this.get('traegerhoehe') + 0.1 : this.get('y');
      var z = -this.get('spaltmass') / 2 - this.get('traegerbreite') / 2;

      if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
          x = this.get('traegerlaenge') / 2;
        }

        x = x + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
      } else {
        y = y + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
      }

      return x + ' ' + y + ' ' + z;
    }),
    gabelspaltmassTextTranslation2: Ember.computed('model.firstObject.transformHelper', function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
      var x = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : this.get('x') / 2;
      var y = this.get('traegerhoehe') + 0.1 > this.get('y') ? this.get('traegerhoehe') + 0.1 : this.get('y');
      var z = this.get('spaltmass') / 2 + this.get('traegerbreite') / 2;

      if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
          x = this.get('traegerlaenge') / 2;
        }

        x = x + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
      } else {
        y = y + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
      }

      return x + ' ' + y + ' ' + z;
    }),
    gabelspaltmassTextRotation: Ember.computed('model.firstObject.transformHelper', function () {
      var values;
      var x3d = this.get('model').objectAt(0);

      if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        values = '1 1 1 ' + this.winkelRadinat(240);
      } else {
        values = '0 1 0 ' + -this.winkelRadinat(90);
      }

      return values;
    }),
    gabelspaltmassText: Ember.computed('model.firstObject.transformHelper', function () {
      return (this.get('spaltmass') * 10).toFixed(1);
    }),
    gabelspaltmassColor: Ember.computed('model.firstObject.aGabellagerAktiv', function () {
      var x3d = this.get('model').objectAt(0);

      if (x3d.get('aGabellagerAktiv') === true) {
        return '1 0 0';
      } else {
        return '0 0 0';
      }
    }),
    getMassketteKoordinaten: function (punkt1, punkt2, drehachsen, drehwinkel) {
      var skalierungsfaktor = 1;
      var b05 = 0.05 * skalierungsfaktor;
      var b1 = 0.1 * skalierungsfaktor;
      var b5 = 0.5 * skalierungsfaktor;
      var b45 = b5 - b05;
      var b55 = b5 + b05;
      var b6 = 0.6 * skalierungsfaktor;
      var dp = new Object();
      var deltaX = 0;
      var deltaY = 0;
      var p0 = Number(punkt1.x) + ' ' + punkt1.y + ' ' + punkt1.z;
      var p1 = Number(punkt2.x) + ' ' + (Number(punkt2.y) + Number(deltaY)) + ' ' + punkt2.z;
      dp = this.drehungXAchse({
        x: 0,
        y: b6,
        z: 0
      }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p2 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      var p3 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      dp = this.drehungXAchse({
        x: b1,
        y: b5,
        z: 0
      }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p4 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      dp = this.drehungXAchse({
        x: -b1,
        y: b5,
        z: 0
      }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p5 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      dp = this.drehungXAchse({
        x: -b05,
        y: b45,
        z: 0
      }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p6 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p8 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      dp = this.drehungXAchse({
        x: b05,
        y: b55,
        z: 0
      }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p7 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p9 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;
      return koordinaten;
    },
    drehungXAchse: function (v, omega) {
      var r = new Object();
      r.x = v.x;
      r.y = v.y * Math.cos(omega) - v.z * Math.sin(omega);
      r.z = v.y * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },
    drehungYAchse: function (v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) + v.z * Math.sin(omega);
      r.y = v.y;
      r.z = -v.x * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },
    drehungZAchse: function (v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) - v.y * Math.sin(omega);
      r.y = v.x * Math.sin(omega) + v.y * Math.cos(omega);
      r.z = v.z;
      return r;
    },
    ansichtLinks: false,
    ansichtOben: false,
    ansichtFront: false,
    // setView: computed('model.firstObject.lastViewpoint', function () {
    //   var x3d = this.get('model').objectAt(0);
    //   var bauteile = x3d.get('bauteile');
    //
    //   console.log("***** setView *****");
    //
    //   if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true){
    //     this.set('ansichtLinks', true);
    //   }else{
    //     this.set('ansichtLinks', false);
    //   }
    //
    //   if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true){
    //     this.set('ansichtOben', true);
    //   }else{
    //     this.set('ansichtOben', false);
    //   }
    //
    //   if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') === true){
    //     this.set('ansichtFront', true);
    //   }else{
    //     this.set('ansichtFront', false);
    //   }
    //
    //   console.log("ansichtLinks: "+this.get('ansichtLinks'));
    //   console.log("ansichtOben: "+this.get('ansichtOben'));
    //   console.log("ansichtFront: "+this.get('ansichtFront'));
    //
    //   return true;
    // }),
    textgroesse: Ember.computed('model.firstObject.{skalierungsfaktor,transformHelper}', function () {
      var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      return 0.3 * skalierungsfaktor;
    })
  });
  var _default = Transform;
  _exports.default = _default;
});