define("m12-2020/models/traeger", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var traeger = _model.default.extend({
    MatKeyTimberElement: (0, _model.attr)('string'),
    FKLKeyTimberElement: (0, _model.attr)('string'),
    TimberElement_b: (0, _model.attr)('string'),
    TimberElement_h: (0, _model.attr)('string'),
    TimberElement_Predrilled: (0, _model.attr)('boolean', {
      defaultValue: function () {
        return false;
      }
    }),
    alphaRAD_vmFaser: (0, _model.attr)('string')
  });

  var _default = traeger;
  _exports.default = _default;
});