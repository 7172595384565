define("m12-2020/components/bo-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Box = Ember.Component.extend({
    tagName: 'box',
    attributeBindings: ['size', 'solid', 'ccw', 'usegeocache', 'lit'],
    solid: true,
    ccw: true,
    usegeocache: true,
    lit: true,
    size: Ember.computed('model.firstObject.transformHelper', function () {
      var name = this.get('name');
      var m06 = this.get('model');
      var bauteile = m06.m06default.objectAt(0).get('bauteile');
      var values;
      bauteile.forEach(function (item, index) {
        if (item.id === name) {
          values = bauteile.objectAt(index).get('boxsizes').objectAt(0).get('x') + " " + bauteile.objectAt(index).get('boxsizes').objectAt(0).get('y') + " " + bauteile.objectAt(index).get('boxsizes').objectAt(0).get('z');
        }
      });
      return values;
    })
  });
  var _default = Box;
  _exports.default = _default;
});