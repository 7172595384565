define("m12-2020/models/x3ddefault", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var X3D = _model.default.extend({
    modul: (0, _model.attr)(''),
    transformHelper: (0, _model.attr)(''),
    viewpointHelper: (0, _model.attr)(''),
    bauteile: (0, _model.hasMany)('bauteil', {
      async: false
    }),
    dimensionen: (0, _model.hasMany)('dimension', {
      async: false
    }),
    resetFieldOfView: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    hWinkel: (0, _model.attr)('number', {
      defaultValue: 90
    }),
    vWinkel: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    bemessungslast: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    min_n0: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    min_n90: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    min_a1cPlatte: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    min_a2cPlatte: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    sicherheitsAbstand: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    lastViewpoint: (0, _model.attr)('string', {
      defaultValue: 'viewpointDefault'
    }),
    aktuellerSchraubenTyp: (0, _model.attr)('string', {
      defaultValue: 'standardSchraube'
    }),
    htbreiteAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    hthoeheAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    abreiteAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    alaengeAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    ueberstandAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    abstandsmassAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    bemessungslastAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    min_n0Aktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    min_n90Aktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    min_a1cPlatteAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    min_a2cPlatteAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    sicherheitsAbstandAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    routeOptionenAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    htBreiteEingetragen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    htHoeheEingetragen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    aBreiteEingetragen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    aLaengeEingetragen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    ueberstandEingetragen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    abstandsmassEingetragen: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    min_n0Eingetragen: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    min_n90Eingetragen: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    min_a1cPlatteEingetragen: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    min_a2cPlatteEingetragen: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    sicherheitsAbstandEingetragen: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    bemessungslastEingetragen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    schraubenlisteAktualisiert: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    skalierungsfaktor: (0, _model.attr)('number', {
      defaultValue: function () {
        return 1;
      }
    }),
    startDistance: (0, _model.attr)('number', {
      defaultValue: 15
    }),
    aktuelleDistance: (0, _model.attr)('number', {
      defaultValue: 15
    }),
    textRefresh: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    randAbstand1: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    randAbstand2: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    schraubenAbstand1: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    schraubenAbstand2: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    abstaendeAnzeigen: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    ergebnisGeladen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    istGitterModell: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    gabellager: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    bGabellagerAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    lGabellagerAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    hGabellagerAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    aGabellagerAktiv: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    bGabellagerEingetragen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    lGabellagerEingetragen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    hGabellagerEingetragen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    aGabellagerEingetragen: (0, _model.attr)('boolean', {
      defaultValue: false
    })
  });

  var _default = X3D;
  _exports.default = _default;
});