define("m12-2020/adapters/x3ddefault", ["exports", "@ember-data/adapter/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationAdapter extends _rest.default {}

  _exports.default = ApplicationAdapter;
});