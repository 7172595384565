define("m12-2020/controllers/supercontroller", ["exports", "m12-2020/mixins/model-validator", "jquery"], function (_exports, _modelValidator, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modelValidator.default, {
    applicationController: Ember.inject.controller('application'),
    auflagerController: Ember.inject.controller('auflager'),
    verbindungsmittelController: Ember.inject.controller('verbindungsmittel'),
    downloadcenterController: Ember.inject.controller('verbindungsmittel'),
    testAusgabe: function (text) {
      console.log("##### Supercontroller Test #####");
      console.log(text);
    },
    resetVerbindungsmittel: function () {
      var self = this;
      var applicationdata = self.get('applicationController').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);
      self.get('applicationController').set('verbindungsmittelInvalid', true);
      self.get('downloadcenterController').set('pdfErstellt', false);
      self.get('downloadcenterController').set('dxfErstellt', false);
      self.get('verbindungsmittelController').set('nurValideSchrauben', false);
      var x3d = self.get('applicationController').get('model').x3ddefault.objectAt(0);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('ergebnisGeladen', false);
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
    },
    uebergabedatenErzeugen: function () {
      var self = this;
      var applicationdata = self.get('applicationController').get('model').application.objectAt(0);
      var applicationdataJSON = JSON.stringify(applicationdata);
      var projektdatendata = self.get('applicationController').get('model').projektdaten.objectAt(0);
      var projektdatendataJSON = JSON.stringify(projektdatendata);
      var traegerdata = self.get('applicationController').get('model').traeger.objectAt(0);
      var traegerdataJSON = JSON.stringify(traegerdata);
      var auflagerdata = self.get('applicationController').get('model').auflager.objectAt(0);
      var auflagerdataJSON = JSON.stringify(auflagerdata);
      var optionendata = self.get('applicationController').get('model').optionen.objectAt(0);
      var optionendataJSON = JSON.stringify(optionendata);
      var lasteinwirkungdata = self.get('applicationController').get('model').lasteinwirkung.objectAt(0);
      var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);

      if (self.debug) {
        console.log("projektdatendata: " + projektdatendataJSON);
        console.log("applicationdataJSON: " + applicationdataJSON);
        console.log("traegerdataJSON: " + traegerdataJSON);
        console.log("auflagerdataJSON: " + auflagerdataJSON);
        console.log("lasteinwirkungdataJSON: " + lasteinwirkungdataJSON);
      }

      var uebergabedaten = _jquery.default.extend(true, JSON.parse(applicationdataJSON), JSON.parse(projektdatendataJSON), JSON.parse(traegerdataJSON), JSON.parse(auflagerdataJSON), JSON.parse(optionendataJSON), JSON.parse(lasteinwirkungdataJSON));

      var timberelement = {
        "Querschnittswerte": {
          "QuerschnittsID": "1",
          "t_Fastener": traegerdata.get('TimberElement_b'),
          "b": traegerdata.get('TimberElement_b'),
          "h": traegerdata.get('TimberElement_h')
        },
        "vorgebohrt": traegerdata.get('TimberElement_Predrilled'),
        "alphaRAD_KraftFaser": "0.0",
        "alphaRAD_vmFaser": traegerdata.get('alphaRAD_vmFaser'),
        "betaRAD_vmFurnier": "90.0",
        "MatKey": traegerdata.get('MatKeyTimberElement'),
        "FKLKey": traegerdata.get('FKLKeyTimberElement')
      };
      var berechnungsoptionen = {
        "min_n0": Number(optionendata.get('min_n0')),
        "min_n90": Number(optionendata.get('min_n90')),
        "min_a1cPlatte": Number(optionendata.get('min_a1cPlatte')),
        "min_a2cPlatte": Number(optionendata.get('min_a2cPlatte')),
        "sicherheitsAbstand": Number(optionendata.get('sicherheitsAbstand'))
      };
      uebergabedaten.timberelem = timberelement;
      uebergabedaten.berechnungsoptionen = berechnungsoptionen;
      return uebergabedaten;
    },
    erzeugeAufrufzusatz: function (zweiterzusatz) {
      // Aufrufe	Zusatz1	Zusatz2
      // initialisiereBerechnung	"?"+kennungland_loadsNA_timberNA
      // getKompaktergebnis	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // erzeugeSchraubenliste	"?"+kennungland_loadsNA_timberNA
      // erzeugeAusgabe	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // erzeugeDXF	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // speichereBerechnung	"?"+kennungland_loadsNA_timberNA
      //
      // Beispiel
      // erzeugeAusgabe/?CH_CH_DE_0170+350+45
      var self = this;
      var applicationdata = self.get('applicationController').get('model').application.objectAt(0);
      var verbindungsmittel = self.get('verbindungsmittelController');
      var zusatzString = "?" + applicationdata.get("kennungland") + "_" + applicationdata.get("loadsNA") + "_" + applicationdata.get("timberNA");

      if (zweiterzusatz === true) {
        var artikelnummer;

        if (typeof verbindungsmittel.get('selectedNodes')[0] === 'undefined') {
          artikelnummer = "Keine Artikel ausgewaehlt";
        } else {
          artikelnummer = verbindungsmittel.get('selectedNodes')[0].data.ArtNr;
        }

        zusatzString = zusatzString + "_" + artikelnummer.replace(/ /g, '+');
      }

      return zusatzString;
    },
    setTraegerLaenge: function (bauteile) {
      var self = this;
      var auflagernaheEinzellast = 0;
      var lLager = Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('x'));
      var lSteutze = Number(bauteile.findBy('id', 'gabellager').get('boxsizes').objectAt(0).get('x'));
      var traegerlaenge = 1.5 * Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y'));

      if (self.get('auflagerController').get('auflagernaheeinzellast') === true) {
        auflagernaheEinzellast = Number(bauteile.findBy('id', 'auflagernaheEinzellast').get('translations').objectAt(0).get('x'));
      }

      traegerlaenge = traegerlaenge + (Number(bauteile.findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) + auflagernaheEinzellast);

      if (self.get('auflagerController').get('aTyp') === true) {
        traegerlaenge = lLager > lSteutze ? traegerlaenge + lLager : traegerlaenge + lLager + (lSteutze - lLager) / 2;
      } else {
        traegerlaenge = lLager > lSteutze ? traegerlaenge + lLager : traegerlaenge + lLager + (lSteutze - lLager);
      }

      bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).set('x', traegerlaenge);
    },
    logaufruf: function (_aufruf, _status, _showOutput, _logAnServerSendenErzwingen) {
      var self = this;
      var application = self.get('applicationController');
      var applicationdata = self.get('applicationController').get('model').application.objectAt(0);
      var date = new Date();
      var datuminticks = date.getTime() * 10000 + 621355968000000000;

      if (_showOutput) {
        console.log(" ");
        console.log("***** logaufruf von *****");
        console.log(_aufruf);
      }

      var tc = applicationdata.get('timberCode').replace(" ", "").substring(0, 3);
      var lc = applicationdata.get('loadsCode').replace(" ", "").substring(0, 3);
      var guid = "00000000-0000-0000-0000-000000000000";
      var guidlength = guid.length;
      var cidlength = applicationdata.get('Calculation_ID').length;
      guid = guid.substring(0, guidlength - cidlength) + applicationdata.get('Calculation_ID');
      var logdaten = {
        "datum": datuminticks,
        "bid": guid,
        "request": 0,
        "firma": enums.Firma.findBy("bezeichnung", applicationdata.get('firm')).id,
        "modul": enums.Modul.findBy("bezeichnung", "m12").id,
        "bemessung": enums.NormBemessung.findBy("bezeichnung", tc).id,
        "bemessungNAD": enums.Sprache.findBy("bezeichnung", applicationdata.get('timberNA').toLowerCase()).id,
        "lastannahmen": enums.NormLastannahmen.findBy("bezeichnung", lc).id,
        "lastannahmenNAD": enums.Sprache.findBy("bezeichnung", applicationdata.get('loadsNA').toLowerCase()).id,
        "eingabeSprache": enums.Sprache.findBy("bezeichnung", applicationdata.get('spracheinput').toLowerCase()).id,
        "ausgabeSprache": enums.Sprache.findBy("bezeichnung", applicationdata.get('spracheoutput').toLowerCase()).id,
        "kennungLand": enums.Sprache.findBy("bezeichnung", applicationdata.get('kennungland').toLowerCase()).id,
        "katalog": enums.Sprache.findBy("bezeichnung", applicationdata.get('katalog').toLowerCase()).id,
        "methode": enums.Methode.findBy("bezeichnung", _aufruf).id,
        "userIp": null,
        "userName": applicationdata.get('userName'),
        "url": null,
        "status": _status,
        "artikelnummer": applicationdata.get('selectedScrew')
      };

      if (_showOutput) {
        console.log("zu lockende Daten:");
        console.log(logdaten);
      }

      var form = new FormData();
      form.append("", JSON.stringify(logdaten)); // form.append("", "{\"datum\":12345,\"bid\":\"00000000-0000-0000-0000-000000000000\",\"request\":0,\"firma\":1,\"modul\":0,\"bemessung\":0,\"bemessungNAD\":0,\"lastannahmen\":0,\"lastannahmenNAD\":0,\"eingabeSprache\":0,\"ausgabeSprache\":0,\"katalog\":0,\"methode\":0,\"userIp\":null,\"url\":null,\"status\":0,\"artikelnummer\":4711}");

      var environment = externalConfig.environments[application.get('environment')];
      var url = externalConfig[environment].uriLogservice; // var url = "http://assy-bemessung.online/webapi/speichereAktion";
      // var url = "https://onlinebemessung.software/webapi/speichereAktion";

      var liveVersion = application.get('environment').search('dev') !== -1 ? false : true;

      if (liveVersion === true || _logAnServerSendenErzwingen === true) {
        _jquery.default.ajax({
          "type": "POST",
          "url": url,
          "processData": false,
          "contentType": false,
          data: form
        }).done(function (data, statusText, xhr) {
          if (_showOutput) {
            console.log("Daten gelockt: " + data);
            console.log("statusText: " + statusText + " ::: status: " + xhr.status); // xhr.status --> 200

            console.log(" ");
          }
        });
      } // testen von der Browserkonsole --> M12.__container__.lookup('controller:supercontroller').logaufruf('erzeugeDXF', 4444, true, true);

    }
  });

  _exports.default = _default;
});