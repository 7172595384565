define("m12-2020/components/transform-erg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = Ember.Component.extend({
    tagName: 'Transform',
    layoutName: 'transform-erg',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    // center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    test: 1337,
    massstab: function () {
      var value = this.get('model').objectAt(0).get('massstab');
      return value;
    },
    center: Ember.computed('model.firstObject.transformHelper', function () {
      var values = '0 0 0';
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);
      var massstab = this.massstab();

      if (name.substring(0, 12) === 'hauptTraeger' || name.substring(0, 18) === 'verstaerkungWerner') {
        values = '0 ' + Number(item.get('boxsizes').objectAt(0).get('y')) / (2 * massstab) + ' 0';
      }

      return values;
    }),
    translation: Ember.computed('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt', function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);
      var values = '0 0 0';
      var xVerschiebung = 0,
          yVerschiebung = 0,
          zVerschiebung = 0,
          verstaerkung = 0,
          deltaVerstaerkung = 0;
      var massstab = this.massstab();

      if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === false) {
        verstaerkung = Number(x3d.get('dickeBlass')) / massstab;
        deltaVerstaerkung = verstaerkung;
      } else if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === true && x3d.get('sFolgeHolzHolz') === true) {
        verstaerkung = Number(x3d.get('dickeWerner')) / massstab;
        deltaVerstaerkung = 2 * verstaerkung;
      } else if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === true && x3d.get('sFolgeHolzHolz') === false) {
        verstaerkung = Number(x3d.get('dickeWerner')) / massstab;
        deltaVerstaerkung = verstaerkung;
      }

      switch (name) {
        case 'nebenTraeger':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / massstab;
          yVerschiebung = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y') / massstab - bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / massstab) / 2;
          zVerschiebung = item.get('translations').objectAt(0).get('z') / massstab;
          break;

        case 'hauptTraeger1':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / massstab;
          yVerschiebung = item.get('translations').objectAt(0).get('y') / massstab;
          zVerschiebung = item.get('boxsizes').objectAt(0).get('z') / (2 * massstab) + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / (2 * massstab) + deltaVerstaerkung;
          break;

        case 'hauptTraeger2':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / massstab;
          yVerschiebung = item.get('translations').objectAt(0).get('y') / massstab;
          zVerschiebung = -(item.get('boxsizes').objectAt(0).get('z') / (2 * massstab) + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / (2 * massstab) + deltaVerstaerkung);
          break;

        case 'verstaerkungBlass1':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / massstab;
          yVerschiebung = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y') / massstab - bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / massstab) / 2;
          zVerschiebung = verstaerkung / 2 + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / (2 * massstab);
          break;

        case 'verstaerkungBlass2':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / massstab;
          yVerschiebung = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y') / massstab - bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / massstab) / 2;
          zVerschiebung = -(verstaerkung / 2 + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / (2 * massstab));
          break;

        case 'verstaerkungWerner1':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / massstab;
          yVerschiebung = item.get('translations').objectAt(0).get('y') / massstab;
          zVerschiebung = 1.5 * verstaerkung + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / (2 * massstab);
          break;

        case 'verstaerkungWerner2':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / massstab;
          yVerschiebung = item.get('translations').objectAt(0).get('y') / massstab;
          zVerschiebung = -(1.5 * verstaerkung + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / (2 * massstab));
          break;
      }

      values = xVerschiebung + " " + yVerschiebung + " " + zVerschiebung;
      return values;
    }),
    rotation: Ember.computed('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt', function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '0 0 0 0';

      if (name.substring(0, 8) === 'schraube' || name === 'cylinder') {
        values = '0 0 0 0';
      } else if (name.substring(0, 12) === 'hauptTraeger' || name.substring(0, 18) === 'verstaerkungWerner') {
        var vwinkel = x3d.get('vWinkel') * Math.PI / 180;
        values = '0 0 1 ' + vwinkel;
      } else if (name.substring(0, 5) !== 'plate') {// values= ( bauteile.findBy('id', name).get('rotations').objectAt(0).get('x') +' '+ bauteile.findBy('id', name).get('rotations').objectAt(0).get('y') +' '+ bauteile.findBy('id', name).get('rotations').objectAt(0).get('z') +' '+ bauteile.findBy('id', name).get('rotations').objectAt(0).get('winkel'));
      }

      return values;
    }),
    getOutline: Ember.computed('', function () {
      var name = this.get('name');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var istVolumen = false;

      if (bauteile.findBy('id', name).get('typ') === 'box' || bauteile.findBy('id', name).get('typ') === 'platte') {
        istVolumen = true;
      }

      return istVolumen;
    }),
    getMassketteHoehe: Ember.computed('model.firstObject.lastViewpoint', 'model.firstObject.abstaendeAnzeigen', function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('ergebnisGeladen') !== true && x3d.get('abstaendeAnzeigen') && (this.get('name') === 'nebenTraeger' || this.get('name') === 'hauptTraeger1' || this.get('name') === 'mittelbauteil' || this.get('name') === 'seitenbauteil0' && Number(x3d.get('vWinkel')) === 0) && bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }),
    getMassketteBreite: Ember.computed('model.firstObject.lastViewpoint', 'model.firstObject.abstaendeAnzeigen', function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('ergebnisGeladen') !== true && x3d.get('abstaendeAnzeigen') && (this.get('name') === 'nebenTraeger' || this.get('name') === 'hauptTraeger1' || this.get('name') === 'mittelbauteil' || this.get('name') === 'seitenbauteil0' || this.get('name') === 'verstaerkungBlass1' || this.get('name') === 'verstaerkungWerner1' && x3d.get('sFolgeHolzHolz') === true) && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }),
    getMassketteSetztiefe: Ember.computed('model.firstObject.transformHelper', 'model.firstObject.lastViewpoint', 'model.firstObject.abstaendeAnzeigen', function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('abstaendeAnzeigen') && this.get('name') === 'hauptTraeger1' && Number(bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).get('setztiefe')) !== 0 && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }),
    getscherkraftPfeil: Ember.computed('model.firstObject.transformHelper', 'model.firstObject.lastViewpoint', function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if ((this.get('name') === 'hauptTraeger1' || name === 'seitenbauteil0') && x3d.get('scherkraftEingetragen') === true && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }),
    istHolz: Ember.computed('model.firstObject.transformHelper', 'model.firstObject.ergebnisGeladen', function () {
      var value = true;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (this.get('name') === 'hauptTraeger1' && (x3d.get('ergebnisGeladen') === true || x3d.get('sFolgeHolzHolz') === false)) {
        value = false;
      }

      return false;
    })
  });
  var _default = Transform;
  _exports.default = _default;
});