define("m12-2020/controllers/gabellager", ["exports", "m12-2020/mixins/object-validator"], function (_exports, _objectValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_objectValidator.default, (_obj = {
    intl: Ember.inject.service(),
    openFromFile: false,
    displayErrors: true,
    gabelbreite: "",
    laenge: "",
    gabelhoehe: "",
    spaltmass: "",
    ttGabelbreite: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 0.1 - 999.0 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttGabellaenge: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 0.1 - 999.0 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttGabelhoehe: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 0.1 - 999.0 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttSpaltmass: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 0.1 - 999.0 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    validations: {
      gabelbreite: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.0,
          message: "!"
        }
      },
      gabellaenge: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.0,
          message: "!"
        }
      },
      gabelhoehe: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.0,
          message: "!"
        }
      },
      spaltmass: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 999.0,
          message: "!"
        }
      }
    },
    applicationController: Ember.inject.controller('application'),
    superController: Ember.inject.controller('supercontroller'),
    initialisierung: function () {
      this.send('validation', -1, {
        target: {
          name: "foobar"
        }
      });
    },
    setValues: function (values) {
      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      var gabelbreiteEvent = {
        target: {
          name: "gabelbreite"
        }
      };
      var gabellaengeEvent = {
        target: {
          name: "gabellaenge"
        }
      };
      var gabelhoeheEvent = {
        target: {
          name: "gabelhoehe"
        }
      };
      var spaltmassEvent = {
        target: {
          name: "spaltmass"
        }
      };
      this.set('openFromFile', true);
      this.set('gabelbreite', values.BStuetze);
      this.setX3D(parseFloat(values.BStuetze), gabelbreiteEvent);
      this.set('gabellaenge', values.LStuetze);
      this.setX3D(parseFloat(values.LStuetze), gabellaengeEvent);
      this.set('gabelhoehe', values.HStuetze);
      this.setX3D(parseFloat(values.HStuetze), gabelhoeheEvent);
      this.set('spaltmass', values.SpaltmassStuetze);
      this.setX3D(parseFloat(values.SpaltmassStuetze), spaltmassEvent);
      this.send('validation');
      this.set('openFromFile', false);
    },
    watchNumericalInputs: Ember.observer('gabelbreite', 'gabellaenge', 'gabelhoehe', 'spaltmass', function () {
      var self = this;

      if (!self.openFromFile) {
        if (typeof self.get('gabelbreite') !== 'undefined' && self.get('gabelbreite').toString().indexOf(",") !== -1) {
          self.set('gabelbreite', self.get('gabelbreite').replace(",", "."));
        }

        if (typeof self.get('gabellaenge') !== 'undefined' && self.get('gabellaenge').toString().indexOf(",") !== -1) {
          self.set('gabellaenge', self.get('gabellaenge').replace(",", "."));
        }

        if (typeof self.get('gabelhoehe') !== 'undefined' && self.get('gabelhoehe').toString().indexOf(",") !== -1) {
          self.set('gabelhoehe', self.get('gabelhoehe').replace(",", "."));
        }

        if (typeof self.get('spaltmass') !== 'undefined' && self.get('spaltmass').toString().indexOf(",") !== -1) {
          self.set('spaltmass', self.get('spaltmass').replace(",", "."));
        }
      }
    }),
    setX3D: function (value, event) {
      var self = this;
      var x3d = self.get('applicationController').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      x3d.set('ergebnisGeladen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case 'gabelbreite':
          if (self.get('errors').get("gabelbreite") === undefined) {
            bauteile.findBy('id', 'gabellager').get('boxsizes').objectAt(0).set('z', value);
            x3d.set('bGabellagerEingetragen', true);
          } else {
            x3d.set('bGabellagerEingetragen', false);
          }

          break;

        case 'gabellaenge':
          if (self.get('errors').get("gabellaenge") === undefined) {
            bauteile.findBy('id', 'gabellager').get('boxsizes').objectAt(0).set('x', value);
            self.get('superController').setTraegerLaenge(bauteile);
            x3d.set('lGabellagerEingetragen', true);
          } else {
            x3d.set('lGabellagerEingetragen', false);
          }

          break;

        case 'gabelhoehe':
          if (self.get('errors').get("gabelhoehe") === undefined) {
            bauteile.findBy('id', 'gabellager').get('boxsizes').objectAt(0).set('y', value);
            self.get('superController').setTraegerLaenge(bauteile);
            x3d.set('hGabellagerEingetragen', true);
          } else {
            x3d.set('hGabellagerEingetragen', false);
          }

          break;

        case 'spaltmass':
          if (self.get('errors').get("spaltmass") === undefined) {
            bauteile.findBy('id', 'gabellager').get('erweiterteeigenschaften').objectAt(0).set('abstandsmass', value);
            self.get('superController').setTraegerLaenge(bauteile);
            x3d.set('aGabellagerEingetragen', true);
          } else {
            x3d.set('aGabellagerEingetragen', false);
          }

          break;
      }

      x3d.set('transformHelper', !x3d.get('transformHelper'));
      self.get('applicationController').zentriertObjekt();
    },

    validation(value, event) {
      var self = this;

      if (self.validate() === true) {
        self.set('displayErrors', false);
        self.get('applicationController').set('gabellagerInvalid', false);
        self.get('superController').resetVerbindungsmittel();
        var gabellager = self.get('applicationController').get('model').gabellager.objectAt(0);
        gabellager.set('BStuetze', parseFloat(self.get('gabelbreite')).toFixed(1));
        gabellager.set('LStuetze', parseFloat(self.get('gabellaenge')).toFixed(1));
        gabellager.set('HStuetze', parseFloat(self.get('gabelhoehe')).toFixed(1));
        gabellager.set('SpaltmassStuetze', parseFloat(self.get('spaltmass')).toFixed(1));
      } else {
        self.set('displayErrors', true);
        self.get('applicationController').set('gabellagerInvalid', true);
      }

      if (!self.openFromFile) {
        this.setX3D(value, event);
      }
    },

    gabelbreiteIsSelected() {
      var bGabellagerAktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('bGabellagerAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('bGabellagerAktiv', !bGabellagerAktiv);

      if (typeof this.get('gabelbreite') !== 'undefined') {
        if (this.get('gabelbreite') !== "") {
          var work = parseFloat(this.get('gabelbreite').toString().replace(",", "."));
          var rounded = work.toFixed(1);
          this.set('gabelbreite', rounded);
          this.send('validation', rounded, {
            target: {
              name: "gabelbreite"
            }
          });
        }

        if (bGabellagerAktiv === false) {
          document.getElementsByName('gabelbreite')[0].setSelectionRange(0, this.get('gabelbreite').length);
        }
      }
    },

    gabellaengeIsSelected() {
      var lGabellagerAktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('lGabellagerAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('lGabellagerAktiv', !lGabellagerAktiv);

      if (typeof this.get('gabellaenge') !== 'undefined') {
        if (this.get('gabellaenge') !== "") {
          var work = parseFloat(this.get('gabellaenge').toString().replace(",", "."));
          var rounded = work.toFixed(1);
          this.set('gabellaenge', rounded);
          this.send('validation', rounded, {
            target: {
              name: "gabellaenge"
            }
          });
        }

        if (lGabellagerAktiv === false) {
          document.getElementsByName('gabellaenge')[0].setSelectionRange(0, this.get('gabellaenge').length);
        }
      }
    },

    gabelhoeheIsSelected() {
      var hGabellagerAktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('hGabellagerAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('hGabellagerAktiv', !hGabellagerAktiv);

      if (typeof this.get('gabelhoehe') !== 'undefined') {
        if (this.get('gabelhoehe') !== "") {
          var work = parseFloat(this.get('gabelhoehe').toString().replace(",", "."));
          var rounded = work.toFixed(1);
          this.set('gabelhoehe', rounded);
          this.send('validation', rounded, {
            target: {
              name: "gabelhoehe"
            }
          });
        }

        if (hGabellagerAktiv === false) {
          document.getElementsByName('gabelhoehe')[0].setSelectionRange(0, this.get('gabelhoehe').length);
        }
      }
    },

    spaltmassIsSelected() {
      var aGabellagerAktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('aGabellagerAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('aGabellagerAktiv', !aGabellagerAktiv);

      if (typeof this.get('spaltmass') !== 'undefined') {
        if (this.get('spaltmass') !== "") {
          var work = parseFloat(this.get('spaltmass').toString().replace(",", "."));
          var rounded = work.toFixed(1);
          this.set('spaltmass', rounded);
          this.send('validation', rounded, {
            target: {
              name: "spaltmass"
            }
          });
        }

        if (aGabellagerAktiv === false) {
          document.getElementsByName('spaltmass')[0].setSelectionRange(0, this.get('spaltmass').length);
        }
      }
    }

  }, (_applyDecoratedDescriptor(_obj, "validation", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "validation"), _obj), _applyDecoratedDescriptor(_obj, "gabelbreiteIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "gabelbreiteIsSelected"), _obj), _applyDecoratedDescriptor(_obj, "gabellaengeIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "gabellaengeIsSelected"), _obj), _applyDecoratedDescriptor(_obj, "gabelhoeheIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "gabelhoeheIsSelected"), _obj), _applyDecoratedDescriptor(_obj, "spaltmassIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "spaltmassIsSelected"), _obj)), _obj));

  _exports.default = _default;
});