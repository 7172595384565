define("m12-2020/templates/components/selectbox-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9qfLroYE",
    "block": "{\"symbols\":[],\"statements\":[[5,\"input\",[[12,\"name\",[23,0,[\"value\",\"id\"]]]],[[\"@type\",\"@checked\"],[\"checkbox\",[23,0,[\"value\",\"checked\"]]]]],[0,\" \"],[1,[23,0,[\"value\",\"id\"]],false],[0,\" \"],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m12-2020/templates/components/selectbox-item.hbs"
    }
  });

  _exports.default = _default;
});