define("m12-2020/templates/components/screw-tablehead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zqAgyO2l",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"schraubentabelle\"],[8],[0,\"\\n  \"],[7,\"span\",false],[12,\"class\",\"tableheadEintrag\"],[3,\"on\",[\"click\",[23,0,[\"toggleAuswahl\"]]]],[8],[0,\" \"],[1,[22,\"text\"],true],[0,\"\\n    \"],[7,\"svg\",true],[10,\"width\",\"1em\"],[10,\"height\",\"1em\"],[10,\"viewBox\",\"0 0 16 16\"],[10,\"class\",\"bi bi-funnel\"],[10,\"fill\",\"currentColor\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n      \"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"d\",\"M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"tabellenkopf \",[28,\"if\",[[23,0,[\"hideAuswahl\"]],\"displayNone\"],null]]]],[8],[0,\"\\n    \"],[7,\"span\",false],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"sortDown\"]],[24,[\"parameter\"]]],null]]],[8],[0,\"↑\"],[9],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"span\",false],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"sortUp\"]],[24,[\"parameter\"]]],null]]],[8],[0,\"↓\"],[9],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[5,\"input\",[[12,\"name\",\"alleschrauben\"]],[[\"@type\",\"@checked\"],[\"checkbox\",[23,0,[\"alleschrauben\"]]]]],[0,\" \"],[1,[28,\"t\",[\"allesauswaehlen\"],null],false],[0,\"\\n    \"],[7,\"hr\",true],[10,\"class\",\"strich\"],[8],[9],[0,\"\\n    \"],[5,\"multiselect-box\",[],[[\"@parameterobjekte\",\"@parameter\",\"@cfilter\"],[[22,\"parameterObjekteListe\"],[22,\"parameter\"],[23,0,[\"thfilter\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m12-2020/templates/components/screw-tablehead.hbs"
    }
  });

  _exports.default = _default;
});