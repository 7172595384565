define("m12-2020/models/anschlussgeometrie", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var anschlussgeometrie = _model.default.extend({
    ALFA_Vertical_GRAD: (0, _model.attr)('string'),
    ALFA_Horizontal_GRAD: (0, _model.attr)('string'),
    SrVar: (0, _model.attr)('string'),
    SrPaare: (0, _model.attr)('string'),
    Max_nSrGrp: (0, _model.attr)('string'),
    QuerZugNW: (0, _model.attr)('boolean')
  });

  anschlussgeometrie.reopenClass({
    FIXTURES: [{
      id: 1,
      ALFA_Vertical_GRAD: "0",
      ALFA_Horizontal_GRAD: "90",
      SrVar: '1',
      SrPaare: 'true',
      Max_nSrGrp: '0',
      QuerZugNW: true
    }]
  });
  var _default = anschlussgeometrie;
  _exports.default = _default;
});