define("m12-2020/components/indexedlineset-erg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Indexedlineset = Ember.Component.extend({
    tagName: 'Transform',
    layoutName: 'indexedlineset-erg',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    objektSkalierung: 1,
    ueberstand: 2,
    yDeltaOben: 0,
    yDeltaUnten: 0,
    translation: Ember.computed('model.firstObject.transformHelper', function () {
      var name = this.get('name');

      if (name === 'queranschluss2') {
        name = 'queranschluss1';
      }

      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var bezug = this.get('typ');
      var x = 0;
      var y = 0;
      var z = 0;
      var cords;
      var item = x3d.get('bauteile').findBy('id', name);

      if (typ === 'lastenPfeil') {
        x = item.get('translations').objectAt(0).get('x');

        if (x3d.get('ergebnisGeladen') === true) {
          y = (Number(bauteile.findBy('id', 'mittelbauteil').get('punkt').objectAt(1).get('y')) + (Number(bauteile.findBy('id', 'mittelbauteil').get('punkt').objectAt(0).get('y')) - Number(bauteile.findBy('id', 'mittelbauteil').get('punkt').objectAt(1).get('y'))) / 2) / 10;
          x = this.scherkraftXWert(y) / 10;
        } else {
          y = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / 20;
        }

        if (name === 'scherkraftPfeil') {
          if (x3d.get('ergebnisGeladen') === true) {
            var verstaerkung = 0;

            if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === false) {
              verstaerkung = x3d.get('dickeBlass') / 10;
            } else if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === true) {
              verstaerkung = x3d.get('dickeWerner') / 10 * 2;
            }

            z = Number(bauteile.findBy('id', 'seitenbauteil0').get('platte').objectAt(0).get('thickness')) / 20 + verstaerkung;

            if (x3d.get('zweiSchaerflaechen') === true) {
              z = z + Number(bauteile.findBy('id', 'mittelbauteil').get('platte').objectAt(0).get('thickness')) / 20;
            }
          } else {
            z = 0;
          }
        } else {
          z = item.get('translations').objectAt(0).get('z');
        }

        cords = x + ' ' + y + ' ' + z;
      }

      return cords;
    }),
    rotation: Ember.computed('model.firstObject.transformHelper', function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values;
      var item = x3d.get('bauteile').findBy('id', name);

      if (item.get('typ') === 'lastenPfeil') {
        var winkel = item.get('rotations').objectAt(0).get('winkel');

        if (name === 'scherkraftPfeil') {
          if (x3d.get('ergebnisGeladen')) {
            winkel = 1.5708 + x3d.get('vWinkel') * Math.PI / 180;
          }

          winkel = Number(winkel) + Number(x3d.get('vWinkelScherkraft')) * Math.PI / 180;
        }

        values = item.get('rotations').objectAt(0).get('x') + " " + item.get('rotations').objectAt(0).get('y') + " " + item.get('rotations').objectAt(0).get('z') + " " + winkel;
      }

      return values;
    }),
    koordinaten: Ember.computed('model.firstObject.transformHelper', 'model.firstObject.{viewpointHelper,vWinkel,hWinkel,istHt,skalierungsfaktor,schraubenGekreuzt}', function () {
      console.log("##### indexedlineset-erg koordinaten #####");
      var name = this.get('name');

      if (name === 'queranschluss2') {
        name = 'queranschluss1';
      }

      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var hwinkel = Number(x3d.get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;
      var x = 0;
      var y = 0;
      var z = 0;
      var skalierungsfaktor = x3d.get('skalierungsfaktor');
      var cords = "1 2 3 -1 3 2 1 -1";
      var p1 = new Object();
      var p2 = new Object();
      var drehwinkel = new Object();
      var w90Grad = 90 * Math.PI / 180;
      var w180Grad = 180 * Math.PI / 180;
      var item = x3d.get('bauteile').findBy('id', name);
      var ueberstand = Number(this.get('ueberstand'));
      var verstaerkung = 0;

      if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === false) {
        verstaerkung = x3d.get('dickeBlass') / 10;
      } else if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === true) {
        verstaerkung = x3d.get('dickeWerner') / 10;
      }

      if (x3d.get('ergebnisGeladen') === false && item.get('typ') === 'box') {
        x = item.get('boxsizes').objectAt(0).get('x') / 10;
        y = item.get('boxsizes').objectAt(0).get('y') / 10;
        z = item.get('boxsizes').objectAt(0).get('z') / 10;

        if (typ === 'kontur') {
          if (name === 'nebenTraeger') {
            cords = this.getKonturKoordinaten(x, y, z);
          } else {
            cords = this.getKonturKoordinaten(x, y, z);
          }
        }

        if (x3d.get('ergebnisGeladen') === false && typ === 'faserrichtung' && (name.substring(0, 12) === 'hauptTraeger' || name === 'nebenTraeger')) {
          cords = this.getFaserrichtungsPfeile(item);
        }

        switch (typ) {
          case 'breite':
            var xVerschiebung = -Math.abs(x3d.get('xSeitenbauteil') / 10);
            var yVerschiebung = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;

            if (name === 'nebenTraeger') {
              xVerschiebung = -Math.abs(x3d.get('xSeitenbauteil') / 10) - ueberstand;
            }

            if (name.substring(0, 12) === 'hauptTraeger' && hwinkel !== 0) {
              p1.x = -Number(this.getXSeitenbauteilOben());
              p2.x = -Number(this.getXSeitenbauteilOben());
              p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
              p2.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);
            } else if (name.substring(0, 17) === 'verstaerkungBlass') {
              p1.x = Math.abs(x3d.get('xMittelbauteil') / 10);
              p2.x = Math.abs(x3d.get('xMittelbauteil') / 10);
              p1.z = verstaerkung / 2;
              p2.z = -verstaerkung / 2;
            } else if (name.substring(0, 18) === 'verstaerkungWerner') {
              p1.x = Math.abs(x3d.get('xMittelbauteil') / 10) + ueberstand;
              p2.x = Math.abs(x3d.get('xMittelbauteil') / 10) + ueberstand;
              p1.z = verstaerkung / 2;
              p2.z = -verstaerkung / 2;
            } else {
              p1.x = xVerschiebung;
              p2.x = xVerschiebung;
              p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
              p2.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);
            }

            p1.y = yVerschiebung;
            p2.y = yVerschiebung;

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              var richtung = 1;

              if (name.substring(0, 18) === 'verstaerkungWerner' || name.substring(0, 17) === 'verstaerkungBlass') {
                richtung = -1;
              }

              drehwinkel.x = 0;
              drehwinkel.y = -w90Grad;
              drehwinkel.z = richtung * w90Grad;
            } else {
              drehwinkel.x = 0;
              drehwinkel.y = -w90Grad;
              drehwinkel.z = 0;
            }

            cords = this.getMassketteKoordinaten(p1, p2, drehwinkel);
            break;

          case 'hoehe':
            var xVerschiebung = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
            var zVerschiebung = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

            if (name === 'nebenTraeger') {
              p1.x = -Math.abs(x3d.get('xSeitenbauteil') / 10) - ueberstand;
              p1.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p1.z = -zVerschiebung;
              p2.x = -Math.abs(x3d.get('xSeitenbauteil') / 10) - ueberstand;
              p2.y = 0;
              p2.z = -zVerschiebung;

              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                if (x3d.get('zweiSchaerflaechen') === true && name === 'nebenTraeger') {
                  p1.z = p1.z - Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z')) / 10;
                }

                p1.z = p1.z - verstaerkung;
                p1.z = p1.z - verstaerkung;
                p2.z = p1.z;
                drehwinkel.x = -w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = w90Grad;
              } else {
                drehwinkel.x = 0;
                drehwinkel.y = 0;
                drehwinkel.z = w90Grad;
              }
            } else if (name === 'hauptTraeger1') {
              p1.x = Math.abs(x3d.get('xMittelbauteil') / 10) + ueberstand;
              p1.y = 0;
              p1.z = zVerschiebung;
              p2.x = Math.abs(x3d.get('xMittelbauteil') / 10) + ueberstand;
              p2.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p2.z = zVerschiebung;

              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                drehwinkel.x = w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = -w90Grad;
              } else {
                drehwinkel.x = 0;
                drehwinkel.y = 0;
                drehwinkel.z = -w90Grad;
              }
            }

            cords = this.getMassketteKoordinaten(p1, p2, drehwinkel);
            break;

          case 'setztiefe':
            p1.x = -Math.abs(x3d.get('xSeitenbauteil') / 10);
            p1.y = 0;
            p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
            p2.x = -Math.abs(x3d.get('xSeitenbauteil') / 10);
            p2.y = 0;
            p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 - bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).get('setztiefe') / 10;

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              p1.x = -Math.abs(x3d.get('xSeitenbauteil') / 10) - 1 - skalierungsfaktor * 0.5;
              p1.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p2.x = -Math.abs(x3d.get('xSeitenbauteil') / 10) - 1 - skalierungsfaktor * 0.5;
              p2.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              drehwinkel.x = 0;
              drehwinkel.y = -w90Grad;
              drehwinkel.z = w90Grad;
            } else {
              drehwinkel.x = 0;
              drehwinkel.y = -w90Grad;
              drehwinkel.z = -w180Grad;
            }

            cords = this.getMassketteKoordinaten(p1, p2, drehwinkel);
            break;
        }
      }

      if (name === 'bBox' && typ === 'kontur') {
        x = item.get('boxsizes').objectAt(0).get('x') / 10;
        y = item.get('boxsizes').objectAt(0).get('y') / 10;
        z = item.get('boxsizes').objectAt(0).get('z') / 10;
        cords = [-x / 2, 0, -z / 2, -x / 2, 0, z / 2, y / 2, 0, z / 2, x / 2, 0, -z / 2, -x / 2, y, -z / 2, -x / 2, y, z / 2, y / 2, y, z / 2, x / 2, y, -z / 2].toString();
      } // console.log(bauteile);
      // console.log("name: "+name);
      // console.log("item");
      // console.log(item);


      if (x3d.get('ergebnisGeladen') === true && item.get('typ') === 'platte') {
        switch (typ) {
          case 'kontur':
            cords = this.getErgebnisKonturKoordinaten();
            break;

          case 'faserrichtung':
            cords = this.getFaserrichtungsPfeile(item);
            break;

          case 'breite':
            p2.x = Number(item.get('punkt').objectAt(0).get('x')) / 10;
            p2.y = Number(item.get('punkt').objectAt(0).get('y')) / 10;
            p2.z = Number(item.get('punkt').objectAt(0).get('z')) / 10;
            p1.x = Number(item.get('punkt').objectAt(0).get('x')) / 10;
            p1.y = Number(item.get('punkt').objectAt(0).get('y')) / 10;
            p1.z = Number(item.get('punkt').objectAt(0).get('z')) / 10 + Number(item.get('platte').objectAt(0).get('thickness')) / 10;

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              drehwinkel.x = 0;
              drehwinkel.y = -w90Grad;
              drehwinkel.z = w90Grad;
            } else {
              drehwinkel.x = 0;
              drehwinkel.y = -w90Grad;
              drehwinkel.z = 0;
            }

            cords = this.getMassketteKoordinaten(p1, p2, drehwinkel);
            break;

          case 'hoehe':
            if (name === 'mittelbauteil') {
              p2.y = Number(item.get('punkt').objectAt(1).get('y')) / 10;
              p1.y = Number(item.get('punkt').objectAt(0).get('y')) / 10;

              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                p2.x = Number(item.get('punkt').objectAt(1).get('x')) / 10;
                p2.z = Number(item.get('punkt').objectAt(1).get('z')) / 10 - 0.5 - this.postionDurchSkalierung(1);
                p1.x = Number(item.get('punkt').objectAt(0).get('x')) / 10;

                if (x3d.get('zweiSchaerflaechen') === true) {
                  p2.z = p2.z - Number(x3d.get('bauteile').findBy('id', 'seitenbauteil1').get('platte').objectAt(0).get('thickness')) / 10;
                  p2.z = p2.z - verstaerkung;
                }

                p1.z = p2.z;
                drehwinkel.x = -w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = w90Grad;
              } else {
                p2.x = Number(item.get('punkt').objectAt(1).get('x')) / 10 - 0.5 - this.postionDurchSkalierung(1);
                p2.z = Number(item.get('punkt').objectAt(1).get('z')) / 10;
                p1.x = Number(item.get('punkt').objectAt(0).get('x')) / 10 - 0.5 - this.postionDurchSkalierung(1);
                p1.z = Number(item.get('punkt').objectAt(0).get('z')) / 10;
                drehwinkel.x = 0;
                drehwinkel.y = 0;
                drehwinkel.z = w90Grad;
              }
            } else if (name === 'seitenbauteil0') {
              p1.x = Number(item.get('punkt').objectAt(2).get('x')) / 10;
              p1.y = Number(item.get('punkt').objectAt(2).get('y')) / 10;
              p1.z = Number(item.get('punkt').objectAt(2).get('z')) / 10 + Number(item.get('platte').objectAt(0).get('thickness')) / 10;
              p2.x = Number(item.get('punkt').objectAt(3).get('x')) / 10;
              p2.y = Number(item.get('punkt').objectAt(3).get('y')) / 10;
              p2.z = Number(item.get('punkt').objectAt(3).get('z')) / 10 + Number(item.get('platte').objectAt(0).get('thickness')) / 10;

              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                drehwinkel.x = w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = -w90Grad + vwinkel;
              } else {
                drehwinkel.x = 0;
                drehwinkel.y = 0;
                drehwinkel.z = -w90Grad + vwinkel;
              }
            }

            cords = this.getMassketteKoordinaten(p1, p2, drehwinkel);
            break;
        }
      }

      if (name === typ) {
        p1.x = item.get('punkt').objectAt(1).get('x') / 10;
        p1.y = item.get('punkt').objectAt(1).get('y') / 10;
        p1.z = item.get('punkt').objectAt(1).get('z') / 10;
        p2.x = item.get('punkt').objectAt(0).get('x') / 10;
        p2.y = item.get('punkt').objectAt(0).get('y') / 10;
        p2.z = item.get('punkt').objectAt(0).get('z') / 10;
        var richtungsvektor1 = new Object();
        var richtungsvektor2 = new Object();
        var sichtEbene = "AnsichtXY";

        if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
          sichtEbene = "AnsichtXZ";
        } else if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
          sichtEbene = "AnsichtYZ";
        }

        sichtEbene = 'masskette' + sichtEbene + item.id.substring(0, item.id.length - 3);
        richtungsvektor1.x = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('x'));
        richtungsvektor1.y = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('y'));
        richtungsvektor1.z = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('z'));
        richtungsvektor2 = richtungsvektor1;
        var ebene = Number(item.get('masskette').objectAt(0).get('ebene'));
        cords = this.getVektorKoordinaten(p1, p2, richtungsvektor1, richtungsvektor2, ebene);
      }

      if (typ === 'lastenPfeil') {
        //Lastenpfeile werden in y-Richtung gezeichnet und müssen anschließend gedreht werden.
        //Grund sind die Pfeilspitzen (Cone) die nur in y-Richtung erstellt werden können und
        //anschließend gedreht werden müssen. Daher sind y-Werte und z-Werte vertauscht.
        var endpunkt1 = 0;
        var endpunkt2 = 0;
        var verschiebungZ = 0;
        var x1 = 0;

        if (name === 'axialkraftPfeil') {
          endpunkt1 = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;

          if (x3d.get('zweiSchaerflaechen')) {
            endpunkt1 = endpunkt1 + bauteile.findBy('id', 'hauptTraeger2').get('boxsizes').objectAt(0).get('z') / 10 + verstaerkung;
          }

          endpunkt2 = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 10 + verstaerkung;
        } else if (name === 'scherkraftPfeil') {
          endpunkt1 = 2;
          endpunkt2 = 2;

          if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === true) {
            verstaerkung = verstaerkung * 2;
          }

          if (x3d.get('ergebnisGeladen') === true) {
            // verschiebungZ = Number(bauteile.findBy('id', 'mittelbauteil').get('platte').objectAt(0).get('thickness'))/20 + Number(bauteile.findBy('id', 'seitenbauteil0').get('platte').objectAt(0).get('thickness'))/20 + verstaerkung;
            verschiebungZ = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 20 + verstaerkung;
          } else {
            verschiebungZ = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 20 + verstaerkung;
          }
        }

        if (x3d.get('zweiSchaerflaechen')) {
          cords = x1 + ' ' + 0 + ' ' + -verschiebungZ + ', ' + x1 + ' ' + (endpunkt1 + 1) + ' ' + -verschiebungZ + ', ' + x1 + ' ' + 0 + ' 0, ' + x1 + ' ' + (-endpunkt2 - 1) + ' 0, ' + x1 + ' ' + 0 + ' ' + -2 * verschiebungZ + ', ' + x1 + ' ' + (-endpunkt2 - 1) + ' ' + -2 * verschiebungZ;
        } else {
          cords = x1 + ' ' + 0 + ' ' + -verschiebungZ + ', ' + x1 + ' ' + (endpunkt1 + 1) + ' ' + -verschiebungZ + ', ' + x1 + ' ' + 0 + ' 0, ' + x1 + ' ' + (-endpunkt2 - 1) + ' 0';
        }
      }

      if (this.get('typ') === 'vWinkel' && name === 'hauptTraeger1') {
        var r1 = 0;
        var w1 = 0;
        var w2 = x3d.get('vWinkel');
        r1 = Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('x')) / 20 + 3;
        cords = this.getArc2dCords(w1 * Math.PI / 180, w2 * Math.PI / 180, r1);
      }

      if (typ === 'vWinkelScherkraft' && name === 'hauptTraeger1') {
        var r1 = 0;
        var w1 = Number(x3d.get('vWinkel'));
        var w2 = Number(x3d.get('vWinkelScherkraft'));
        r1 = Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('x')) / 20 + 4;
        cords = this.getArc2dCords(w1 * Math.PI / 180, (w1 + w2) * Math.PI / 180, r1);
      }

      return cords;
    }),
    koordinatenIndex: Ember.computed('model.firstObject.transformHelper', 'model.firstObject.istHt', function () {
      console.log("##### indexedlineset-erg koordinaten index #####");
      var name = this.get('name');

      if (name === 'queranschluss2') {
        name = 'queranschluss1';
      }

      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";
      var item = x3d.get('bauteile').findBy('id', name);

      if (item.get('typ') === 'box' || item.get('typ') === 'platte') {
        if (typ === 'kontur') {
          if (x3d.get('ausmittigerstabanschluss') === true && (name.substring(0, 12) === 'hauptTraeger' || name.substring(0, 18) === 'verstaerkungWerner')) {
            values = "0 1 2 3 0 -1 4 5 6 7 4 -1 0 8 4 -1 1 9 5 -1 2 6 -1 3 7 -1 8 9";
          } else if (x3d.get('ausmittigerstabanschluss') === true && name.substring(0, 13) === 'seitenbauteil') {
            values = "0 1 2 3 8 0 -1 4 5 6 7 9 4 -1 8 9 -1 0 4 -1 1 5 -1 2 6 -1 3 7 -1";
          } else {
            if (item.get('typ') === 'box') {
              values = "0 1 2 3 0 -1 4 5 6 7 4 -1 0 4 -1 1 5 -1 2 6 -1 3 7";
            }

            if (item.get('typ') === 'platte') {
              values = "";

              for (var i = 0; i < item.get('punkt').length; i++) {
                values = values + " " + i;
              }

              values = values + " 0 -1 ";
              var arr = []; // Abschlussfläche

              for (var i = 0; i < item.get('punkt').length; i++) {
                arr.push(i + item.get('punkt').length);
              }

              var str = arr.reverse().toString();
              values = values + item.get('punkt').length + " " + str.replace(/,/g, " ") + " -1 ";
              values = values + 0 + " " + item.get('punkt').length + " -1 1 " + (1 + item.get('punkt').length) + " -1 2 " + (2 + item.get('punkt').length) + " -1 " + (item.get('punkt').length - 1) + " " + (item.get('punkt').length - 1 + item.get('punkt').length) + " -1";
            }
          }
        }

        if (typ === 'faserrichtung') {
          values = "0 1 -1 1 2 -1 2 3 -1";
        }
      } else {
        if (typ === 'lastenPfeil') {
          if (x3d.get('zweiSchaerflaechen')) {
            values = "0 1 -1 2 3 -1 4 5 -1";
          } else {
            values = "0 1 -1 2 3 -1";
          }
        }

        if (name.substring(0, 8) === 'schraube' && x3d.get('ergebnisGeladen')) {
          if (typ === 'xPos' && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = "0 0";
          } else if (typ === 'zPos' && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
            values = "0 0";
          } else {
            values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";
          }
        }
      }

      if (this.get('typ') === 'vWinkel' && name === 'hauptTraeger1' || typ === 'vWinkelScherkraft' && name === 'hauptTraeger1') {
        values = "0 1 -1 2 3 -1";
      }

      return values;
    }),
    istLastenpfeil: Ember.computed('', function () {
      var istLastenpfeil = false;

      if (this.get('typ') === 'lastenPfeil') {
        istLastenpfeil = true;
      }

      return istLastenpfeil;
    }),
    zweiSchaerflaechen: Ember.computed('model.firstObject.zweiSchaerflaechen', function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);

      if (this.get('name') === 'scherkraftPfeil' && x3d.get('zweiSchaerflaechen')) {
        value = true;
      }

      return value;
    }),
    emissivecolor: Ember.computed('model.firstObject.exzentrizitaetDiagonaleAktiv', 'model.firstObject.ueberstandDiagonaleVertikalAktiv', 'model.firstObject.htbreiteAktiv', 'model.firstObject.hthoeheAktiv', 'model.firstObject.ntbreiteAktiv', 'model.firstObject.nthoeheAktiv', 'model.firstObject.axialkraftAktiv', 'model.firstObject.scherkraftAktiv', 'model.firstObject.vWinkelAktiv', 'model.firstObject.vWinkelScherkraftAktiv', 'model.firstObject.setztiefeAktiv', 'model.firstObject.dickeWernerAktiv', 'model.firstObject.dickeBlassAktiv', function () {
      var name = this.get('name');

      if (name === 'queranschluss2') {
        name = 'queranschluss1';
      }

      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var item = x3d.get('bauteile').findBy('id', name);
      var emsvcolor = '0 0 0';

      if (x3d.get('ntbreiteAktiv') === true && name === 'nebenTraeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('nthoeheAktiv') === true && name === 'nebenTraeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('htbreiteAktiv') === true && name.substring(0, 12) === 'hauptTraeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('hthoeheAktiv') === true && name.substring(0, 12) === 'hauptTraeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('setztiefeAktiv') === true && name === 'hauptTraeger1' && typ === 'setztiefe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('vWinkelScherkraftAktiv') === true && name === 'hauptTraeger1' && typ === 'vWinkelScherkraft') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('vWinkelAktiv') === true && name === 'hauptTraeger1' && typ === 'vWinkel') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('axialkraftAktiv') === true && name === 'axialkraftPfeil' && typ === 'lastenPfeil') {
        emsvcolor = '1 0 0';
      } else if (x3d.get('axialkraftAktiv') === false && name === 'axialkraftPfeil' && typ === 'lastenPfeil') {
        emsvcolor = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');
      }

      if (x3d.get('scherkraftAktiv') === true && name === 'scherkraftPfeil' && typ === 'lastenPfeil') {
        emsvcolor = '1 0 0';
      } else if (x3d.get('scherkraftAktiv') === false && name === 'scherkraftPfeil' && typ === 'lastenPfeil') {
        emsvcolor = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');
      }

      if ((x3d.get('dickeBlassAktiv') === true || x3d.get('dickeWernerAktiv') === true) && name.substring(0, 12) === 'verstaerkung' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('ueberstandDiagonaleVertikalAktiv') === true && name === 'nebenTraeger' && typ === 'ueberstandDiagonaleVertikal') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('exzentrizitaetDiagonaleAktiv') === true && name === 'nebenTraeger' && typ === 'exzentrizitaetDiagonale') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }),
    getUrsprungMasskette: function (ursprung, richtung, verschiebung) {
      ursprung.x = ursprung.x + richtung.x * verschiebung;
      ursprung.y = ursprung.y + richtung.y * verschiebung;
      ursprung.z = ursprung.z + richtung.z * verschiebung;
      return ursprung;
    },
    getKonturKoordinaten: function (x, y, z) {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var deltaXht = 0;
      var deltaXnt = 0;
      var xMittelbauteil = 0;
      var xSeitenbauteilUnten = 0;
      var xPlusSeitenbauteilUnten = 0;
      var xSeitenbauteilOben = 0;
      var ueberstand = Number(this.get('ueberstand'));
      var x1 = 0,
          x2 = 0;
      var verstaerkung = 0;

      if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === false) {
        verstaerkung = Number(x3d.get('dickeBlass'));
      } else if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === true) {
        verstaerkung = Number(x3d.get('dickeWerner'));
      }

      switch (name) {
        case 'nebenTraeger':
          // x = 2.5*bauteile.findBy('id', 'queranschluss1').get('boxsizes').objectAt(0).get('x')/10;
          deltaXnt = Math.abs(x3d.get('xSeitenbauteil') / 10) + ueberstand;
          xMittelbauteil = Math.abs(x3d.get('xMittelbauteil') / 10);
          break;

        case 'hauptTraeger1':
          deltaXht = Math.abs(x3d.get('xMittelbauteil') / 10) + ueberstand;
          xSeitenbauteilOben = this.getXSeitenbauteilOben();
          xSeitenbauteilUnten = this.getXSeitenbauteilUnten();
          xPlusSeitenbauteilUnten = this.getXDeltaSeitenbauteilUnten(xSeitenbauteilUnten);
          break;

        case 'hauptTraeger2':
          deltaXht = Math.abs(x3d.get('xMittelbauteil') / 10) + ueberstand;
          xSeitenbauteilOben = this.getXSeitenbauteilOben();
          xSeitenbauteilUnten = this.getXSeitenbauteilUnten();
          xPlusSeitenbauteilUnten = this.getXDeltaSeitenbauteilUnten(xSeitenbauteilUnten);
          break;

        case 'verstaerkungBlass1':
          deltaXnt = Math.abs(x3d.get('xSeitenbauteil') / 10) + ueberstand;
          xMittelbauteil = Math.abs(x3d.get('xMittelbauteil') / 10);
          z = verstaerkung / 10;
          break;

        case 'verstaerkungBlass2':
          deltaXnt = Math.abs(x3d.get('xSeitenbauteil') / 10) + ueberstand;
          xMittelbauteil = Math.abs(x3d.get('xMittelbauteil') / 10);
          z = verstaerkung / 10;
          break;

        case 'verstaerkungWerner1':
          deltaXht = Math.abs(x3d.get('xMittelbauteil') / 10) + ueberstand;
          xSeitenbauteilOben = this.getXSeitenbauteilOben();
          xSeitenbauteilUnten = this.getXSeitenbauteilUnten();
          xPlusSeitenbauteilUnten = this.getXDeltaSeitenbauteilUnten(xSeitenbauteilUnten);
          z = verstaerkung / 10;
          break;

        case 'verstaerkungWerner2':
          deltaXht = Math.abs(x3d.get('xMittelbauteil') / 10) + ueberstand;
          xSeitenbauteilOben = this.getXSeitenbauteilOben();
          xSeitenbauteilUnten = this.getXSeitenbauteilUnten();
          xPlusSeitenbauteilUnten = this.getXDeltaSeitenbauteilUnten(xSeitenbauteilUnten);
          z = verstaerkung / 10;
          break;
      }

      var p0 = -deltaXnt - xPlusSeitenbauteilUnten + ' ' + (0 + Number(this.get('yDeltaUnten'))) + ' ' + -z / 2;
      var p1 = -deltaXnt - xPlusSeitenbauteilUnten + ' ' + (0 + Number(this.get('yDeltaUnten'))) + ' ' + z / 2;
      var p2 = deltaXht + xMittelbauteil + ' ' + (0 + Number(this.get('yDeltaUnten'))) + ' ' + z / 2;
      var p3 = deltaXht + xMittelbauteil + ' ' + (0 + Number(this.get('yDeltaUnten'))) + ' ' + -z / 2;
      var p4 = -deltaXnt - xSeitenbauteilOben + ' ' + (y - Number(this.get('yDeltaOben'))) + ' ' + -z / 2;
      var p5 = -deltaXnt - xSeitenbauteilOben + ' ' + (y - Number(this.get('yDeltaOben'))) + ' ' + z / 2;
      var p6 = deltaXht + xMittelbauteil + ' ' + (y - Number(this.get('yDeltaOben'))) + ' ' + z / 2;
      var p7 = deltaXht + xMittelbauteil + ' ' + (y - Number(this.get('yDeltaOben'))) + ' ' + -z / 2;
      var p8 = 0;
      var p9 = 0;

      if (x3d.get('ausmittigerstabanschluss') === true && (name.substring(0, 12) === 'hauptTraeger' || name.substring(0, 18) === 'verstaerkungWerner')) {
        p8 = -deltaXnt - xSeitenbauteilUnten - this.getXExzentrizitaet('x') + ' ' + this.getXExzentrizitaet('y') + ' ' + -z / 2;
        p9 = -deltaXnt - xSeitenbauteilUnten - this.getXExzentrizitaet('x') + ' ' + this.getXExzentrizitaet('y') + ' ' + z / 2;
      } // this.setMaximalPunkte('p0', p0.split(" ")[0], p0.split(" ")[1], p0.split(" ")[2]);
      // this.setMaximalPunkte('p1', p1.split(" ")[0], p1.split(" ")[1], p1.split(" ")[2]);
      // this.setMaximalPunkte('p2', p2.split(" ")[0], p2.split(" ")[1], p2.split(" ")[2]);
      // this.setMaximalPunkte('p3', p3.split(" ")[0], p3.split(" ")[1], p3.split(" ")[2]);
      // this.setMaximalPunkte('p4', p4.split(" ")[0], p4.split(" ")[1], p4.split(" ")[2]);
      // this.setMaximalPunkte('p5', p5.split(" ")[0], p5.split(" ")[1], p5.split(" ")[2]);
      // this.setMaximalPunkte('p6', p6.split(" ")[0], p6.split(" ")[1], p6.split(" ")[2]);
      // this.setMaximalPunkte('p7', p7.split(" ")[0], p7.split(" ")[1], p7.split(" ")[2]);
      // this.setMaximalPunkte('p8', p8.split(" ")[0], p8.split(" ")[1], p8.split(" ")[2]);
      // this.setMaximalPunkte('p9', p9.split(" ")[0], p9.split(" ")[1], p9.split(" ")[2]);
      // console.log('name:'+name);
      // console.log('p0: '+p0);
      // console.log('p1: '+p1);
      // console.log('p2: '+p2);
      // console.log('p3: '+p3);
      // console.log('p4: '+p4);
      // console.log('p5: '+p5);
      // console.log('p6: '+p6);
      // console.log('p7: '+p7);
      // console.log('p8: '+p8);
      // console.log('p9: '+p9);


      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;
      return koordinaten;
    },
    getXSeitenbauteilOben: function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var hDiag = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y');
      var hGurt = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y');
      ;
      var alfa = parseFloat(x3d.get('vWinkel'));
      var beta = parseFloat(x3d.get('winkelEndAbschnittDiagonaleGRAD'));
      var ueDiagVert = parseFloat(x3d.get('ueberstandDiagonaleVertikal'));
      var value = 0;

      if (alfa !== 0 && alfa !== 90) {
        var exzentrizitaet = 0;

        if (x3d.get('ausmittigerstabanschluss') === true) {
          exzentrizitaet = x3d.get('exzentrizitaetDiagonale');
        } else {
          exzentrizitaet = Number(hDiag / 2 / Math.sin(alfa * Math.PI / 180)) + Number(hGurt / 2 / Math.tan(alfa * Math.PI / 180));

          if (Number(x3d.get('winkelEndAbschnittGRAD')) === 1 && Number(ueDiagVert) > hDiag * Math.cos(alfa * Math.PI / 180)) {
            exzentrizitaet = exzentrizitaet + (ueDiagVert - hDiag * Math.cos(alfa * Math.PI / 180)) / Math.tan(alfa * Math.PI / 180);
          } else if (Number(x3d.get('winkelEndAbschnittGRAD')) === 2) {
            exzentrizitaet = exzentrizitaet + ueDiagVert / Math.tan(alfa * Math.PI / 180);
          }
        }

        value = (exzentrizitaet / Math.cos(alfa * Math.PI / 180) - hDiag / 2 * Math.tan(alfa * Math.PI / 180)) / 10;
      } else if (alfa === 90) {
        value = hGurt / 20;
      } else {
        value = Math.abs(x3d.get('xSeitenbauteil') / 10) + Math.sin(alfa * Math.PI / 180) * ueDiagVert / 10;
      }

      if (value < -(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')))) {
        var xOben = Math.abs(value) - Math.abs(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')));
        var yDeltaOben = Math.abs(xOben) / Math.tan(alfa * Math.PI / 180);
        this.set('yDeltaOben', yDeltaOben);
        value = -(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')));
      } else {
        this.set('yDeltaOben', 0);
      }

      return value;
    },
    getXSeitenbauteilUnten: function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var hDiag = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y');
      var hGurt = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y');
      ;
      var alfa = parseFloat(x3d.get('vWinkel'));
      var beta = parseFloat(x3d.get('winkelEndAbschnittDiagonaleGRAD'));
      var ueDiagVert = parseFloat(x3d.get('ueberstandDiagonaleVertikal'));
      var exzentrizitaet = x3d.get('exzentrizitaetDiagonale');
      var v1 = 0,
          v2 = 0,
          l = 0,
          value = 0;
      v1 = hDiag / 2 / Math.sin(alfa * Math.PI / 180) + hGurt / 2 / Math.tan(alfa * Math.PI / 180) - exzentrizitaet;
      v2 = hDiag / Math.sin(alfa * Math.PI / 180);
      l = v2 - v1;

      if (alfa !== 0 && alfa !== 90) {
        value = hGurt / 2 / Math.sin(alfa * Math.PI / 180) - (v2 - hDiag / 2 / Math.sin(alfa * Math.PI / 180)) * Math.cos(alfa * Math.PI / 180);

        if (x3d.get('ausmittigerstabanschluss') === false) {
          if (Number(x3d.get('winkelEndAbschnittGRAD')) === 1 && Number(ueDiagVert) >= Number((hDiag * Math.cos(alfa * Math.PI / 180)).toFixed(1))) {
            value = value + hDiag / Math.tan(alfa * Math.PI / 180) + (ueDiagVert - hDiag * Math.cos(alfa * Math.PI / 180)) / Math.sin(alfa * Math.PI / 180);
          } else if (Number(x3d.get('winkelEndAbschnittGRAD')) === 2) {
            value = value + ueDiagVert / Math.sin(alfa * Math.PI / 180);
          }
        } else if (x3d.get('ausmittigerstabanschluss') === true) {
          if (Number(x3d.get('winkelEndAbschnittGRAD')) === 1) {
            // value = value + (l-ueDiagVert/Math.tan(alfa * Math.PI / 180)) * Math.cos(alfa * Math.PI / 180) + ueDiagVert/Math.sin(alfa * Math.PI / 180);
            value = value + ueDiagVert / Math.sin(alfa * Math.PI / 180);
          } else if (Number(x3d.get('winkelEndAbschnittGRAD')) === 2) {
            value = value + ueDiagVert / Math.sin(alfa * Math.PI / 180);
          }

          if (value >= Number(((hDiag / 2 + exzentrizitaet / Math.sin(alfa * Math.PI / 180)) * Math.tan(alfa * Math.PI / 180)).toFixed(1))) {
            value = (hDiag / 2 + exzentrizitaet / Math.sin(alfa * Math.PI / 180)) * Math.tan(alfa * Math.PI / 180);
          }
        }

        value = value / 10;
      } else if (alfa === 90) {
        value = hGurt / 20;
      } else {
        value = Math.abs(x3d.get('xSeitenbauteil') / 10) + Math.sin(alfa * Math.PI / 180) * ueDiagVert / 10;
      }

      if (value < -(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')))) {
        var xUnten = Math.abs(value) - Math.abs(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')));
        var yDeltaUnten = Math.abs(xUnten) * Math.tan(alfa * Math.PI / 180);
        this.set('yDeltaUnten', yDeltaUnten); // value = -(Math.abs(x3d.get('xMittelbauteil')/10) + Number(this.get('ueberstand')));
      } else {
        this.set('yDeltaUnten', 0);
      }

      return value;
    },
    getXDeltaSeitenbauteilUnten: function (value) {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);

      if (value < -(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')))) {
        value = -(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')));
      }

      return value;
    },
    getXExzentrizitaet: function (achse) {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var alfa = parseFloat(x3d.get('vWinkel'));
      var beta = parseFloat(x3d.get('winkelEndAbschnittDiagonaleGRAD'));
      var ueDiagVert = parseFloat(x3d.get('ueberstandDiagonaleVertikal'));
      var hDiag = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y');
      var hGurt = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y');
      ;
      var exzentrizitaet = Number(x3d.get('exzentrizitaetDiagonale'));
      var v1 = 0,
          v2 = 0,
          l = 0,
          value = 0;
      v1 = hDiag / 2 / Math.sin(alfa * Math.PI / 180) + hGurt / 2 / Math.tan(alfa * Math.PI / 180) - exzentrizitaet;
      v2 = hDiag / Math.sin(alfa * Math.PI / 180);
      l = v2 - v1;

      if (l - ueDiagVert / Math.tan(alfa * Math.PI / 180) >= 0) {
        if (achse === 'x') {
          if (Number(x3d.get('winkelEndAbschnittGRAD')) === 1) {
            value = 0;
          } else if (Number(x3d.get('winkelEndAbschnittGRAD')) === 2) {
            value = (l - ueDiagVert / Math.tan(alfa * Math.PI / 180)) * Math.cos(alfa * Math.PI / 180);
          }
        } else if (achse === 'y') {
          if (Number(x3d.get('winkelEndAbschnittGRAD')) === 1) {
            value = (l - ueDiagVert / Math.tan(alfa * Math.PI / 180)) / Math.sin(alfa * Math.PI / 180);
          } else if (Number(x3d.get('winkelEndAbschnittGRAD')) === 2) {
            value = (l - ueDiagVert / Math.tan(alfa * Math.PI / 180)) * Math.sin(alfa * Math.PI / 180);
          }
        }
      }

      value = value / 10;
      return value;
    },
    getErgebnisKonturKoordinaten: function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name); // var p0 = '0 0 0', p1 = '0 0 0', p2 = '0 0 0', p3 = '0 0 0', p4 = '0 0 0', p5 = '0 0 0', p6 = '0 0 0', p7 = '0 0 0', p8 = '0 0 0', p9 = '0 0 0';
      //

      var x0 = 0;
      var y0 = 0;
      var z0 = 0; // var x1 = Number(item.get('punkt').objectAt(1).get('x'))/10;
      // var y1 = Number(item.get('punkt').objectAt(1).get('y'))/10;
      // var z1 = Number(item.get('punkt').objectAt(1).get('z'))/10;
      // var x2 = Number(item.get('punkt').objectAt(2).get('x'))/10;
      // var y2 = Number(item.get('punkt').objectAt(2).get('y'))/10;
      // var z2 = Number(item.get('punkt').objectAt(2).get('z'))/10;
      // var x3 = Number(item.get('punkt').objectAt(3).get('x'))/10;
      // var y3 = Number(item.get('punkt').objectAt(3).get('y'))/10;
      // var z3 = Number(item.get('punkt').objectAt(3).get('z'))/10;

      var rx = 0;
      var ry = 0;
      var rz = 0;
      var dicke = 0; //hintere Ebene
      // p0 = x0 +' '+ y0 +' '+ z0;
      // p1 = x1 +' '+ y1 +' '+ z1;
      // p2 = x2 +' '+ y2 +' '+ z2;
      // p3 = x3 +' '+ y3 +' '+ z3;
      // fordere Ebene
      // p4 = (x0+rx*dicke) +' '+ (y0+ry*dicke) +' '+ (z0+rz*dicke);
      // p5 = (x1+rx*dicke) +' '+ (y1+ry*dicke) +' '+ (z1+rz*dicke);
      // p6 = (x2+rx*dicke) +' '+ (y2+ry*dicke) +' '+ (z2+rz*dicke);
      // p7 = (x3+rx*dicke) +' '+ (y3+ry*dicke) +' '+ (z3+rz*dicke);
      // var x4 = 0, y4 = 0, z4 = 0;
      //
      // if(item.get('punkt').length > 4){
      //   x4 = Number(item.get('punkt').objectAt(4).get('x'))/10;
      //   y4 = Number(item.get('punkt').objectAt(4).get('y'))/10;
      //   z4 = Number(item.get('punkt').objectAt(4).get('z'))/10;
      // }
      //
      // var p8 = x4 +' '+ y4 +' '+ z4;
      // var p9 = x4+' '+ y4+' '+ (z4+dicke);
      // var cords = p0 +', '+ p1 +', '+ p2 +', '+ p3 +', '+ p4 +', '+ p5 +', '+ p6 +', '+ p7+', '+ p8 +', '+ p9;

      var cords = "";

      for (var n = 0; n <= 1; n++) {
        for (var i = 0; i < item.get('punkt').length; i++) {
          x0 = Number(item.get('punkt').objectAt(i).get('x')) / 10;
          y0 = Number(item.get('punkt').objectAt(i).get('y')) / 10;
          z0 = Number(item.get('punkt').objectAt(i).get('z')) / 10;
          cords = cords + (x0 + rx * dicke) + ' ' + (y0 + ry * dicke) + ' ' + (z0 + rz * dicke) + ' ';
        }

        rx = Number(item.get('richtungsvektor').objectAt(0).get('x'));
        ry = Number(item.get('richtungsvektor').objectAt(0).get('y'));
        rz = Number(item.get('richtungsvektor').objectAt(0).get('z'));
        dicke = Number(item.get('platte').objectAt(0).get('thickness')) / 10;
      }

      return cords;
    },
    getMassketteKoordinaten: function (punkt1, punkt2, drehwinkel) {
      var typ = this.get('typ');
      var skalierungsfaktor = 1;
      var b05 = 0.05 * skalierungsfaktor;
      var b1 = 0.1 * skalierungsfaktor;
      var b5 = 0.5 * skalierungsfaktor;
      var b45 = b5 - b05;
      var b55 = b5 + b05;
      var b6 = 0.6 * skalierungsfaktor;
      var hwinkel = Number(this.get('model').objectAt(0).get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(this.get('model').objectAt(0).get('vWinkel')) * Math.PI / 180;
      var dp = new Object();
      var deltaX = 0;
      var deltaY = 0;
      var p0 = Number(punkt1.x) + ' ' + punkt1.y + ' ' + punkt1.z;
      var p1 = Number(punkt2.x) + ' ' + (Number(punkt2.y) + Number(deltaY)) + ' ' + punkt2.z;
      dp = this.drehungXAchse({
        x: 0,
        y: b6,
        z: 0
      }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p2 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      var p3 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      dp = this.drehungXAchse({
        x: b1,
        y: b5,
        z: 0
      }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p4 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      dp = this.drehungXAchse({
        x: -b1,
        y: b5,
        z: 0
      }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p5 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      dp = this.drehungXAchse({
        x: -b05,
        y: b45,
        z: 0
      }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p6 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p8 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      dp = this.drehungXAchse({
        x: b05,
        y: b55,
        z: 0
      }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p7 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p9 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;
      return koordinaten;
    },
    delta: function (l, winkel) {
      var value = l * Math.cos(winkel);
      return value;
    },
    winkel: function (x, y) {
      return Math.atan(x / y);
    },
    vektorenWinkel: function (v1, v2) {
      var zaehler = v1.x * v2.x + v1.y * v2.y + v1.z * v2.z; // console.log('zaehler: '+zaehler);

      var n1 = Math.sqrt(Math.pow(v1.x, 2) + Math.pow(v1.y, 2) + Math.pow(v1.z, 2)); // console.log('n1: '+n1);

      var n2 = Math.sqrt(Math.pow(v2.x, 2) + Math.pow(v2.y, 2) + Math.pow(v2.z, 2)); // console.log('n2: '+n2);

      var nenner = n1 * n2; // console.log('nenner: '+nenner);
      // console.log('zaehler/nenner: '+zaehler/nenner);

      var alpha = Math.acos((zaehler / nenner).toFixed(6));
      return alpha;
    },
    differenzVektor: function (v2, v1) {
      var vResult = new Object();
      vResult.x = v2.x - v1.x;
      vResult.y = v2.y - v1.y;
      vResult.z = v2.z - v1.z;
      return vResult;
    },
    drehungXAchse: function (v, omega) {
      var r = new Object();
      r.x = v.x;
      r.y = v.y * Math.cos(omega) - v.z * Math.sin(omega);
      r.z = v.y * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },
    drehungYAchse: function (v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) + v.z * Math.sin(omega);
      r.y = v.y;
      r.z = -v.x * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },
    drehungZAchse: function (v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) - v.y * Math.sin(omega);
      r.y = v.x * Math.sin(omega) + v.y * Math.cos(omega);
      r.z = v.z;
      return r;
    },
    getArc2dCords: function (w1, w2, r) {
      var cords = '0 0 0 0 0 0 0 0 0 0 0 0';
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var yVerschiebung = Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')) / 20;
      var z = 0;
      var p1 = new Object();
      var p2 = new Object();
      var p3 = new Object();
      var p4 = new Object();
      p1.x = r * Math.cos(w1);
      p1.y = r * Math.sin(w1);
      p1.z = z;
      p2.x = (r + 1) * Math.cos(w1);
      p2.y = (r + 1) * Math.sin(w1);
      p2.z = z;
      p3.x = r * Math.cos(w2);
      p3.y = r * Math.sin(w2);
      p3.z = z;
      p4.x = (r + 1) * Math.cos(w2);
      p4.y = (r + 1) * Math.sin(w2);
      p4.z = z;
      cords = p1.x + ' ' + p1.y + ' ' + p1.z + ' ' + p2.x + ' ' + p2.y + ' ' + p2.z + ' ' + p3.x + ' ' + p3.y + ' ' + p3.z + ' ' + p4.x + ' ' + p4.y + ' ' + p4.z;
      return cords;
    },
    getFaserrichtungsPfeile: function (item) {
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var name = this.get('name');
      var delta = 0.15;
      var ueberstand = Number(this.get('ueberstand'));
      var x = 0,
          y = 0,
          z = 0;
      var winkel = 0;

      if (item.get('typ') === 'box') {
        x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
        y = 0.15;
        z = item.get('boxsizes').objectAt(0).get('z') / 20;
        var xSeitenbauteil = Math.abs(Number(x3d.get('xSeitenbauteil'))) / 10;
        var xMittelbauteil = Math.abs(Number(x3d.get('xMittelbauteil'))) / 10;

        if (name === 'hauptTraeger1' || name === 'hauptTraeger2') {
          x = -x / 2 - ueberstand + delta;
        } else {
          x = x + xMittelbauteil / 2;
        }
      } else if (item.get('typ') === 'platte') {
        var rx = Number(item.get('richtungsvektor').objectAt(0).get('x'));
        var ry = Number(item.get('richtungsvektor').objectAt(0).get('y'));
        var rz = Number(item.get('richtungsvektor').objectAt(0).get('z'));
        var dicke = item.get('platte').objectAt(0).get('thickness') / 10;
        x = -Number(item.get('punkt').objectAt(1).get('x')) / 10 - rx * dicke;
        y = Number(item.get('punkt').objectAt(1).get('y')) / 10 + 0.15 + ry * dicke;
        z = Number(item.get('punkt').objectAt(1).get('z')) / 10 + rz * dicke;

        if (name.substring(0, 21) === 'verstaerkungdiagonale' || name.substring(0, 13) === 'seitenbauteil') {
          var x10 = Number(item.get('punkt').objectAt(Number(item.get('punkt').length) - 1).get('x')) - Number(item.get('punkt').objectAt(0).get('x'));
          var y10 = Number(item.get('punkt').objectAt(Number(item.get('punkt').length) - 1).get('y')) - Number(item.get('punkt').objectAt(0).get('y'));
          var z10 = Number(item.get('punkt').objectAt(Number(item.get('punkt').length) - 1).get('z')) - Number(item.get('punkt').objectAt(0).get('z'));
          winkel = this.vektorenWinkel({
            x: 1,
            y: 0,
            z: 0
          }, {
            x: x10,
            y: y10,
            z: z10
          });
        }
      }

      var cords = -x + this.deltaX(0.15 + delta, delta * 2, winkel) + ' ' + (y + this.deltaY(0.15 + delta, delta * 2, winkel)) + ' ' + z + ', ' + (-x + this.deltaX(delta, delta, winkel)) + ' ' + (y + this.deltaY(delta, delta, winkel)) + ' ' + z + ', ' + (-x + this.deltaX(1, delta, winkel)) + ' ' + (y + this.deltaY(1, delta, winkel)) + ' ' + z + ', ' + (-x + this.deltaX(0.85, 0, winkel)) + ' ' + (y + this.deltaY(0.85, 0, winkel)) + ' ' + z;
      return cords;
    },
    deltaX: function (x, y, winkel) {
      var erg = x * Math.cos(winkel) - y * Math.sin(winkel);
      return erg;
    },
    deltaY: function (x, y, winkel) {
      var erg = x * Math.sin(winkel) + y * Math.cos(winkel);
      return erg;
    },
    getMittelwert: function (a, b) {
      return (a + b) / 2;
    },
    scherkraftXWert: function (yGurt) {
      var ergebnis = 0;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var vWinkel = x3d.get('vWinkel');
      var P1 = bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(0);
      var P2 = bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(bauteile.findBy('id', 'seitenbauteil0').get('punkt').length - 1);
      var P3 = bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(bauteile.findBy('id', 'seitenbauteil0').get('punkt').length - 2);
      var x1 = P1.get('x');
      var y1 = P1.get('y');
      var x2 = P2.get('x');
      var y2 = P2.get('y');
      var x3 = P3.get('x');
      var y3 = P3.get('y');

      if (Number(vWinkel) === 0) {
        var Pmittelbauteil4 = bauteile.findBy('id', 'mittelbauteil').get('punkt').objectAt(bauteile.findBy('id', 'mittelbauteil').get('punkt').length - 1);
        var xmb4 = Pmittelbauteil4.get('x');
        ergebnis = x1 + (xmb4 - x1) / 2;
      } else if (Number(vWinkel) === 90) {
        ergebnis = x3 + (x2 - x3) / 2;
      } else {
        var dx = (x2 - x3) / 2;
        var dy = (y2 - y3) / 2;
        var m = (y2 - y1) / (x2 - x1);
        var n = y3 + dy - m * (x3 + dx);
        ergebnis = (yGurt * 10 - n) / m;
      }

      return ergebnis;
    },
    postionDurchSkalierung: function (richtung) {
      var x3d = this.get('model').objectAt(0);
      var skalierungsfaktor = x3d.get('skalierungsfaktor') + 0.6;
      var value = 0.2 * skalierungsfaktor;
      return value;
    },
    getVektorKoordinaten: function (punkt1, punkt2, r1, r2, ebene) {
      var dr = 0.2 * (ebene - 1);
      var b05 = 0.05;
      var b1 = 0.1;
      var b5 = 0.5;
      var b45 = b5 - b05;
      var b55 = b5 + b05;
      var b6 = 0.6;
      var verschiebung = b5 * (ebene - 1) + dr;
      var p0 = Number(punkt1.x) + verschiebung * Number(r1.x) + ' ' + (Number(punkt1.y) + verschiebung * Number(r1.y)) + ' ' + (Number(punkt1.z) + verschiebung * Number(r1.z));
      var p1 = Number(punkt2.x) + verschiebung * Number(r2.x) + ' ' + (Number(punkt2.y) + verschiebung * Number(r2.y)) + ' ' + (Number(punkt2.z) + verschiebung * Number(r2.z));
      var p2 = Number(punkt2.x) + (b6 + verschiebung) * Number(r2.x) + ' ' + (Number(punkt2.y) + (b6 + verschiebung) * Number(r2.y)) + ' ' + (Number(punkt2.z) + (b6 + verschiebung) * Number(r2.z));
      var p3 = Number(punkt1.x) + (b6 + verschiebung) * Number(r1.x) + ' ' + (Number(punkt1.y) + (b6 + verschiebung) * Number(r1.y)) + ' ' + (Number(punkt1.z) + (b6 + verschiebung) * Number(r1.z));
      var p4 = this.getXYZ(punkt1, punkt2, r1, r2, b5 + verschiebung, b1);
      var p5 = this.getXYZ(punkt2, punkt1, r2, r1, b5 + verschiebung, b1);
      var p6 = this.getXYZ(punkt2, punkt1, r2, r1, b45 + verschiebung, b05);
      var p8 = this.getXYZ(punkt1, punkt2, r1, r2, b45 + verschiebung, -b05);
      var p7 = this.getXYZ(punkt2, punkt1, r2, r1, b55 + verschiebung, -b05);
      var p9 = this.getXYZ(punkt1, punkt2, r1, r2, b55 + verschiebung, b05);
      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;
      return koordinaten;
    },
    vektorLaenge: function (x, y, z) {
      return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2));
    },
    getXYZ: function (punkt1, punkt2, r1, r2, richtungsAbstand, seitenAbstand) {
      var p1_2 = {
        x: Number(punkt1.x) + richtungsAbstand * Number(r1.x),
        y: Number(punkt1.y) + richtungsAbstand * Number(r1.y),
        z: Number(punkt1.z) + richtungsAbstand * Number(r1.z)
      };
      var p2_2 = {
        x: Number(punkt2.x) + richtungsAbstand * Number(r2.x),
        y: Number(punkt2.y) + richtungsAbstand * Number(r2.y),
        z: Number(punkt2.z) + richtungsAbstand * Number(r2.z)
      };
      var r3 = {
        x: p2_2.x - p1_2.x,
        y: p2_2.y - p1_2.y,
        z: p2_2.z - p1_2.z
      };
      var lr3 = this.vektorLaenge(r3.x, r3.y, r3.z);

      if (lr3 === 0) {
        console.log("!!!!! Bei " + this.get('name') + " Division durch 0 !!!!!");
        console.log('punkt1');
        console.log(punkt1);
        console.log('punkt2');
        console.log(punkt2);
        console.log('Länge von punkt2 zu punkt1');
        console.log(lr3);
        console.log(' ');
        lr3 = 1;
      }

      var r3Einheitsvektor = {
        x: r3.x / lr3,
        y: r3.y / lr3,
        z: r3.z / lr3
      };
      var x = p1_2.x + r3Einheitsvektor.x * (lr3 + seitenAbstand);
      var y = p1_2.y + r3Einheitsvektor.y * (lr3 + seitenAbstand);
      var z = p1_2.z + r3Einheitsvektor.z * (lr3 + seitenAbstand);
      return x + ' ' + y + ' ' + z;
    }
  });
  var _default = Indexedlineset;
  _exports.default = _default;
});