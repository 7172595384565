define("m12-2020/models/gabellager", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var gabellager = _model.default.extend({
    BStuetze: (0, _model.attr)('string'),
    LStuetze: (0, _model.attr)('string'),
    HStuetze: (0, _model.attr)('string'),
    SpaltmassStuetze: (0, _model.attr)('string')
  });

  var _default = gabellager;
  _exports.default = _default;
});