define("m12-2020/components/coordinat-e", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Coordinate = Ember.Component.extend({
    tagName: 'coordinate',
    attributeBindings: ['point'],
    point: Ember.computed('model.x3ddefault.bauteile', function () {
      var name = this.get('name');
      var bauteile = this.get('model.x3ddefault.bauteile');
      var values;
      var item = bauteile.findBy('id', name);
      values = item.punkt.p1 + " " + item.punkt.p2 + " " + item.punkt.p3 + " " + item.punkt.p4;
      return values;
    })
  });
  var _default = Coordinate;
  _exports.default = _default;
});