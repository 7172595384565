define("m12-2020/components/extrusio-n", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Extrusion = Ember.Component.extend({
    tagName: 'extrusion',
    attributeBindings: ['endCap', 'solid', 'creaseAngle', 'crosssection', 'orientation', 'scale', 'spine'],
    endCap: true,
    solid: true,
    ispickable: "0",
    creaseAngle: 7,
    //crossection beschreibt die zu extrodierende Grundform in der xz-Ebene
    crosssection: Ember.computed('model.firstObject.transformHelper', function () {
      var name = this.get('name');
      var values = false;
      var x;
      var z;
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      if (item.get('typ') === "box") {
        x = item.get('boxsizes').objectAt(0).get('x') / 10;
        z = item.get('boxsizes').objectAt(0).get('z') / 10;
        values = '[(' + -x / 2 + ',' + -z / 2 + '),(' + -x / 2 + ',' + z / 2 + '),(' + x / 2 + ',' + z / 2 + '),(' + x / 2 + ',' + -z / 2 + '),(' + -x / 2 + ',' + -z / 2 + ')]';
      }

      return values;
    }),
    //orientation beschreibt die Richtung in der die Extrusion erfolgt
    //Wird die Extrusion in mehreren Schritten/Stufen durchgeführt, ist die Richtung für jede Stufe anzugeben.
    orientation: Ember.computed('model.firstObject.transformHelper', function () {
      var name = this.get('name');
      var values = '0 1 0 0, 0 1 0 0';
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      if (item.get('typ') === "box") {
        values = '[(0,0,0,1)]';
      }

      return values;
    }),
    //scale beschreibt die Skalierung der Grundfläche während eines Extrusionschritts
    //Wird die Extrusion in mehreren Schritten/Stufen durchgeführt, ist die Skalierung für jeden Schritt/Stufe anzugeben.
    scale: Ember.computed('model.firstObject.transformHelper', function () {
      var name = this.get('name');
      var values = '0 0, 0.025 0.025, 0.05 0.05, 0.07 0.07';
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      if (item.get('typ') === "box") {
        values = '[(1,1)]';
      }

      return values;
    }),
    //spine beschreibt die Länge der Extrusion in y-Richtung
    //Die Extrusion kann in mehreren Schritten/Stufen erfolgen
    spine: Ember.computed('model.firstObject.transformHelper', function () {
      var name = this.get('name');
      var values = '0 0 0';
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      if (item.get('typ') === "box") {
        var y = item.get('boxsizes').objectAt(0).get('y') / 10;

        if (name.substring(0, 17) === 'traufkonstruktion') {
          this.get('model').objectAt(0).get('bauteile').findBy('id', 'sparren1').get('boxsizes').objectAt(0).get('y') / 10;
        }

        values = '[(0,0,0), (0 ' + y + ' 0)]';
      }

      return values;
    })
  });
  var _default = Extrusion;
  _exports.default = _default;
});