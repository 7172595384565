define("m12-2020/controllers/impressum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    wuerth: false,
    swg: true,
    spracheDeutsch: true,
    selectedLanguage: "DE",
    init: function () {
      this._super();

      console.log("inti impressum");
    }
  });

  _exports.default = _default;
});